import { createApp } from 'vue';
import App from './App.vue';
import router from './router';

import { IonicVue } from '@ionic/vue';
import { createPinia } from 'pinia';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

import './theme/fonts.css';
import './theme/variables.css';
import './theme/main.scss';

import 'large-small-dynamic-viewport-units-polyfill';
import VueSocialSharing from 'vue-social-sharing';
import { setupCalendar } from 'v-calendar';
import { Capacitor } from '@capacitor/core';

import enLocale from '@/locales/en';
import ruLocale from '@/locales/ru';
import esLocale from '@/locales/es';
import { createI18n } from 'vue-i18n';
import { useUserStore } from './stores/user';
import { useLanguagePrefs } from './stores/preferences';
import { getNormalizedLocale } from './services/methods';

const platform = Capacitor.getPlatform();

const ionicConfig = {
  mode: 'ios',
  swipeBackEnabled: platform === 'ios',
};

const app = createApp(App).use(IonicVue, ionicConfig).use(router);
const pinia = createPinia();

app.use(pinia);
app.use(VueSocialSharing);
app.use(setupCalendar, {});

router.isReady().then(async () => {
  const { get: getLanguage } = useLanguagePrefs();
  const prefferredLang = await getLanguage();
  const routeLang = router.currentRoute.value.query.lang as string;
  const normalizedLang = getNormalizedLocale(
    routeLang || prefferredLang || 'en'
  );
  const i18n = createI18n({
    locale: normalizedLang,
    fallbackLocale: 'en',
    legacy: false,
    warnHtmlMessage: false,
    messages: {
      en: enLocale,
      ru: ruLocale,
      es: esLocale,
    },
  });

  const userStore = useUserStore();
  userStore.setLocale(normalizedLang);
  app.use(i18n);
  app.mount('#app');
});
