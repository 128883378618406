import { Preferences } from '@capacitor/preferences';

interface PrefsStoreParams {
  key: string;
}

function createPrefsStore(params: PrefsStoreParams) {
  const key = params.key;
  return () => {
    const set = async (value: string) => {
      await Preferences.set({
        key,
        value: value || '',
      });
    };

    const get = async () => {
      const { value } = await Preferences.get({ key });

      return value;
    };

    const remove = async () => {
      await Preferences.remove({ key });
    };
    return {
      set,
      get,
      remove,
    };
  };
}

export const useOnboardingCompletionPrefs = createPrefsStore({
  key: 'onboarding',
});

export const useEmergencySupportShowedPrefs = createPrefsStore({
  key: 'emergencySupport',
});

export const useRequestNotificationsShowedPrefs = createPrefsStore({
  key: 'requestNotifications',
});

export const useTutorialShowedPrefs = createPrefsStore({
  key: 'tutorial',
});

export const useAppBannerHidedPrefs = createPrefsStore({
  key: 'appBanner',
});

export const useIsLoggedPrefs = createPrefsStore({
  key: 'isLogged',
});

export const useUserIdPrefs = createPrefsStore({
  key: 'userId',
});

export const useUserDataPrefs = createPrefsStore({
  key: 'userData',
});

export const useAppOpenCount = createPrefsStore({
  key: 'appOpenCount',
});

export const useGivenAppFeedpack = createPrefsStore({
  key: 'useGivenAppFeedpack',
});

export const useFinishedCoursesVisiblePrefs = createPrefsStore({
  key: 'finishedCourses',
});

export const useLanguagePrefs = createPrefsStore({
  key: 'language',
});
export const useCaptionsPrefs = createPrefsStore({
  key: 'captions',
});
