import {
  getFirestore,
  getDocs,
  query,
  where,
  orderBy,
  collection,
  QueryDocumentSnapshot,
  DocumentData,
  startAfter,
  limit,
  QueryConstraint,
  getCountFromServer,
  doc,
  updateDoc,
} from 'firebase/firestore';

import { ApiFetchParamsI, NotificationI } from '@/types';
import { useUserStore } from '@/stores/user';
import { useUserIdPrefs } from '@/stores/preferences';

export function createNotifications() {
  const db = getFirestore();
  const docPath = 'notifications';
  const QUERY_LIMIT = 10;
  let lastVisibleNotificationRef: QueryDocumentSnapshot<DocumentData> | null =
    null;

  return {
    async getAllWithLimit(p?: ApiFetchParamsI) {
      const docRef = collection(db, docPath);
      const userStore = useUserStore();
      let userId: string | null = userStore.user.id;

      if (!userId) {
        const { get: getUserId } = useUserIdPrefs();
        userId = await getUserId();
      }

      if (userId) {
        const result: NotificationI[] = [];
        let params: QueryConstraint[] = [
          orderBy('date', 'desc'),
          where('userId', '==', userId),
          limit(QUERY_LIMIT),
        ];

        if (!p?.fromStart && lastVisibleNotificationRef) {
          params = [...params, startAfter(lastVisibleNotificationRef)];
        }

        const querySnapshot = await getDocs(query(docRef, ...params));

        querySnapshot.forEach(async (doc) => {
          const notification = doc.data() as NotificationI;
          result.push(notification);
        });

        const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
        const isLastPage = querySnapshot.size < QUERY_LIMIT;
        lastVisibleNotificationRef = isLastPage ? null : lastVisible;

        return {
          data: result,
          isLastPage,
        };
      }

      return {
        data: [],
        isLastPage: true,
      };
    },
    async getNotificationsLength() {
      const docRef = collection(db, docPath);
      const userStore = useUserStore();
      const userId: string | null = userStore.user.id;

      if (!userId) {
        return 0;
      }

      const countSnapshot = await getCountFromServer(
        query(
          docRef,
          where('userId', '==', userId),
          where('isViewed', '==', false)
        )
      );
      return countSnapshot.data().count;
    },
    async setIsViewedNotification(id: string, isViewed: boolean) {
      const userDoc = doc(db, docPath, id);
      const notificationParams: Partial<NotificationI> = {
        isViewed,
      };
      await updateDoc(userDoc, notificationParams);
    },
  };
}
