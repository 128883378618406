import {
  getFirestore,
  collection,
  QueryDocumentSnapshot,
  type DocumentData,
  QueryConstraint,
  limit,
  orderBy,
  startAfter,
  getDocs,
  query,
  updateDoc,
  doc,
  getDoc,
  setDoc,
  Timestamp,
} from 'firebase/firestore';

import type { UserI, RequestDataI, PurchaseProductI, UserRateI } from '@/types';

export function createUserRates() {
  const db = getFirestore();
  const usersPath = 'users';
  const userRatesPath = 'user-rates';

  let lastVisibleCourseRef: QueryDocumentSnapshot<DocumentData> | null = null;
  const QUERY_LIMIT = 10;

  return {
    async getAll<T>(
      docPath: string,
      {
        p,
        queryFieldFilterConstraints,
        queryLimit,
        queryOrderByConstraints,
      }: RequestDataI
    ) {
      const docRef = collection(db, docPath);
      const qLimit = queryLimit ?? QUERY_LIMIT;

      if (!queryFieldFilterConstraints) {
        queryFieldFilterConstraints = [];
      }

      const result: T[] = [];
      let params: QueryConstraint[] = [
        ...queryFieldFilterConstraints,
        limit(qLimit),
      ];

      if (queryOrderByConstraints) {
        params = [...params, ...queryOrderByConstraints];
      } else {
        params = [...params, orderBy('order', 'desc')];
      }

      if (!p?.fromStart && lastVisibleCourseRef) {
        params = [...params, startAfter(lastVisibleCourseRef)];
      }

      const querySnapshot = await getDocs(query(docRef, ...params));

      querySnapshot.forEach((doc) => {
        const course = doc.data() as T;
        result.push(course);
      });

      const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
      const isLastPage = querySnapshot.size < qLimit;
      lastVisibleCourseRef = isLastPage ? null : lastVisible;

      return {
        data: result || [],
        isLastPage,
      };
    },
    async getAllUserPurchases(p?: RequestDataI['p']) {
      return await this.getAll<UserI>(usersPath, {
        ...p,
        queryOrderByConstraints: [orderBy('purchasedProducts')],
      });
    },
    async fullfillPurchase(user: UserI, productId: string) {
      const docRef = doc(db, usersPath, user.id);
      if (user.purchasedProducts) {
        const data: Partial<UserI> = {
          purchasedProducts: user.purchasedProducts.map((p) => {
            if (p.productId === productId) {
              p.fulfilled = true;
            }
            return p;
          }),
        };
        await updateDoc(docRef, data);
      }
    },
    async getById(id: string) {
      const querySnapshot = await getDoc(doc(db, userRatesPath, id));
      return querySnapshot.data() as PurchaseProductI;
    },
    async create(rate: Omit<UserRateI, 'id' | 'date'>) {
      const docRef = doc(collection(db, userRatesPath));
      const data: UserRateI = {
        id: docRef.id,
        ...rate,
        date: Timestamp.fromDate(new Date()),
      };
      console.log('RATE DATA', data);

      await setDoc(docRef, data);

      return data;
    },
  };
}
