import {
  doc,
  getFirestore,
  getDocs,
  query,
  where,
  collection,
  setDoc,
  Timestamp,
  orderBy,
  limit,
  updateDoc,
} from 'firebase/firestore';
import { DailyCheckReportI } from '@/types';
import { useUserStore } from '@/stores/user';
import { getDayjsInstance } from '@/services/date';
import { UiDailyCheckReportI } from '@/types/ui';

export function createDailyCheckReports() {
  const db = getFirestore();
  const docPath = 'dailyCheckReports';

  return {
    create: async (params: UiDailyCheckReportI) => {
      const reportsCollection = collection(db, docPath);
      const reportRef = doc(reportsCollection);

      const now = params.date;
      now.setHours(0, 0, 0, 0);

      const createParams: DailyCheckReportI = {
        ...params,
        id: reportRef.id,
        date: Timestamp.fromDate(now),
      };

      await setDoc(reportRef, createParams);

      return createParams;
    },
    updateById: async (id: string, params: DailyCheckReportI) => {
      const docRef = doc(db, docPath, id);
      await updateDoc(docRef, { ...params });
      return params;
    },
    getTodayReport: async () => {
      const reportsRef = collection(db, docPath);
      const userStore = useUserStore();
      const userId: string | null = userStore.user.id;
      if (!userId) {
        return;
      }

      const now = new Date();
      now.setHours(0, 0, 0, 0);
      const sortDate = Timestamp.fromDate(now);

      const reportsQuery = query(
        reportsRef,
        where('date', '==', sortDate),
        where('userId', '==', userId),
        orderBy('date', 'asc'),
        limit(16)
      );
      const querySnapshot = await getDocs(reportsQuery);
      if (querySnapshot.empty) {
        return null;
      }

      return querySnapshot.docs[0].data() as DailyCheckReportI;
    },
    getTwoWeeksFromDate: async (date: Timestamp | 'today') => {
      const res: DailyCheckReportI[] = [];
      const reportsRef = collection(db, docPath);
      const userStore = useUserStore();
      const userId: string | null = userStore.user.id;

      const sortDate = date === 'today' ? Timestamp.now() : date;

      const reportsQuery = query(
        reportsRef,
        where('date', '<=', sortDate),
        where('userId', '==', userId),
        orderBy('date', 'asc'),
        limit(16)
      );
      const querySnapshot = await getDocs(reportsQuery);
      querySnapshot.forEach((theme) => {
        const report = theme.data() as DailyCheckReportI;
        res.push(report);
      });

      return res;
    },
    getMonthFromDate: async (startDate: Date) => {
      const dayjs = getDayjsInstance();
      const endDate = dayjs(startDate).endOf('month').toDate();

      const res: DailyCheckReportI[] = [];
      const reportsRef = collection(db, docPath);
      const userStore = useUserStore();
      const userId: string | null = userStore.user.id;

      const reportsQuery = query(
        reportsRef,
        where('date', '>=', startDate),
        where('date', '<=', endDate),
        where('userId', '==', userId),
        orderBy('date', 'asc')
      );
      const querySnapshot = await getDocs(reportsQuery);
      querySnapshot.forEach((theme) => {
        const report = theme.data() as DailyCheckReportI;
        res.push(report);
      });

      return res;
    },
  };
}
