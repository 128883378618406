import {
  getFirestore,
  doc,
  getDoc,
  collection,
  setDoc,
  QueryDocumentSnapshot,
  type DocumentData,
  QueryConstraint,
  limit,
  startAfter,
  getDocs,
  query,
  updateDoc,
  Timestamp,
  deleteDoc,
  where,
} from 'firebase/firestore';

import type { UserTestItemI, RequestDataI, ApiFetchParamsI } from '@/types';

export function createUserTests() {
  const db = getFirestore();
  const docPath = 'users-tests';
  let lastVisibletestRef: QueryDocumentSnapshot<DocumentData> | null = null;
  const QUERY_LIMIT = 10;

  return {
    async getAll({
      p,
      queryFieldFilterConstraints,
      queryLimit,
      queryOrderByConstraints,
    }: RequestDataI) {
      const docRef = collection(db, docPath);
      const qLimit = queryLimit ?? QUERY_LIMIT;

      if (!queryFieldFilterConstraints) {
        queryFieldFilterConstraints = [];
      }

      const result: UserTestItemI[] = [];
      let params: QueryConstraint[] = [
        ...queryFieldFilterConstraints,
        limit(qLimit),
      ];

      if (queryOrderByConstraints) {
        params = [...params, ...queryOrderByConstraints];
      } else {
        params = [...params];
      }

      if (!p?.fromStart && lastVisibletestRef) {
        params = [...params, startAfter(lastVisibletestRef)];
      }

      const querySnapshot = await getDocs(query(docRef, ...params));

      querySnapshot.forEach((doc) => {
        const test = doc.data() as UserTestItemI;
        result.push(test);
      });

      const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
      const isLastPage = querySnapshot.size < qLimit;
      lastVisibletestRef = isLastPage ? null : lastVisible;

      return {
        data: result || [],
        isLastPage,
      };
    },
    async getByUserId(userId: string, p?: ApiFetchParamsI) {
      return await this.getAll({
        p,
        queryFieldFilterConstraints: [where('userId', '==', userId)],
      });
    },
    async getById(id: string) {
      const res = await getDoc(doc(db, docPath, id));
      return res.data() as UserTestItemI;
    },
    async getByTestId(testId: string, userId: string) {
      const { data } = await this.getAll({
        queryFieldFilterConstraints: [
          where('testId', '==', testId),
          where('userId', '==', userId),
        ],
      });
      return data[0];
    },
    async create(test: Omit<UserTestItemI, 'date' | 'id'>) {
      const testsRef = collection(db, docPath);
      const testRef = doc(testsRef);
      const data: UserTestItemI = {
        ...test,
        id: testRef.id,
        date: Timestamp.now(),
      };
      console.log('test', data);
      await setDoc(testRef, data);
      return data;
    },
    async updateById(id: string, test: Partial<UserTestItemI>) {
      const docRef = doc(db, docPath, id);
      await updateDoc(docRef, { ...test });
    },
    async removeById(id: string) {
      const docRef = doc(db, docPath, id);
      await deleteDoc(docRef);
    },
  };
}
