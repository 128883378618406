import { ref, Ref } from 'vue';
import { toastController } from '@ionic/vue';

export type PopupType = 'error' | 'success';

export function usePopup() {
  const popupText = ref('');
  const popupType: Ref<PopupType> = ref('error');

  async function showPopup(popupType: PopupType, duration?: number) {
    const popupEl = await toastController.create({
      message: popupText.value,
      duration: duration || 2000,
      cssClass: popupType === 'success' ? 'popup--success' : 'popup--error',
    });
    await popupEl.present();
  }

  function toggleErrorPopup(message: string, duration?: number) {
    popupText.value = message;
    popupType.value = 'error';
    showPopup('error', duration);
  }
  function toggleSuccessPopup(message: string, duration?: number) {
    popupText.value = message;
    popupType.value = 'success';
    showPopup('success', duration);
  }

  return {
    popupType,
    toggleErrorPopup,
    toggleSuccessPopup,
  };
}
