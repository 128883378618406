import { computed, reactive } from 'vue';

const modalsState = reactive({
  rateCourse: false,
});

export function useRateCourseModal() {
  function showRateCourseModal() {
    modalsState.rateCourse = true;
  }
  function hideRateCourseModal() {
    modalsState.rateCourse = false;
  }

  return {
    isRateCourseModalVisible: computed(() => modalsState.rateCourse),
    showRateCourseModal,
    hideRateCourseModal,
  };
}
