import { FirebaseAnalytics } from '@capacitor-firebase/analytics';

export const setUserId = async (userId: string) => {
  await FirebaseAnalytics.setUserId({
    userId,
  });
};

export const setUserProperty = async () => {
  await FirebaseAnalytics.setUserProperty({
    key: 'language',
    value: 'en',
  });
};

export const setCurrentScreen = async (name: string) => {
  await FirebaseAnalytics.setCurrentScreen({
    screenName: name,
  });
};

export const logEvent = async (
  event: string,
  params?: Record<string, string>
) => {
  await FirebaseAnalytics.logEvent({
    name: event,
    params,
  });
};

export const resetAnalyticsData = async () => {
  await FirebaseAnalytics.resetAnalyticsData();
};
