<template>
  <button
    type="button"
    class="app-banner"
    @click="install"
  >
    <span class="app-banner__left">
      <button type="button" class="app-banner__close-button" @click.stop="emit('hide')">
        <ion-icon :icon="closeOutline" />
      </button>
      <span class="app-banner__image">
        <img src="/assets/logos/android-chrome-192x192.png" alt="" />
      </span>
      <span class="app-banner__info">
        <span class="app-banner__title">FeelYou – Mental health</span>
        <span class="app-banner__category">Health and Fitness</span>
        <span class="app-banner__store">Get it on Google Play</span>
      </span>
    </span>
    <span class="app-banner__view-button">View</span>
  </button>
</template>

<script setup lang="ts">
import { IonIcon } from '@ionic/vue';
import { closeOutline } from 'ionicons/icons';

const emit = defineEmits(['hide', 'close']);

function install() {
  window.open('https://play.google.com/store/apps/details?id=io.feelyou.app', '_blank');
  emit('close');
}
</script>

<style lang="scss" scoped>
.app-banner {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  background-color: #fff;
  padding: 8px;

  &__left {
    display: flex;
    align-items: center;
    gap: 6px;
  }

  &__close-button {
    background-color: transparent;
  }

  &__image {
    width: 60px;
    height: 60px;

    img {
      width: 100%;
      height: 100%;
      border-radius: 10px;
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 6px;
  }

  &__title {
    font-size: 16px;
    font-weight: 500;
    color: #172659;
    letter-spacing: 0.32px;
  }

  &__category {
    font-size: 12px;
    color: #8a90ac;
    letter-spacing: 0.24px;
  }

  &__store {
    font-size: 14px;
    color: #8a90ac;
    letter-spacing: 0.28px;
  }

  &__view-button {
    font-size: 16px;
    color: #3399fd;
    letter-spacing: 0.32px;
  }
}
</style>
