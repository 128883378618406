import { useI18n } from 'vue-i18n';
import { SourceLang } from '@/types';
import { WritableComputedRef } from 'vue';
import { useLanguagePrefs } from '@/stores/preferences';
import api from '@/api';
import { useUserStore } from '@/stores/user';
import { locales } from '@/stores/constants';
import { useRouter } from 'vue-router';
import { getNormalizedLocale } from '@/services/methods';

export function useInternalization() {
  const { t, tm, locale } = useI18n();
  const { set: setLanguagePref } = useLanguagePrefs();
  const userStore = useUserStore();
  const router = useRouter();

  async function setLocale(lang: SourceLang | string) {
    const normalizedLang = getNormalizedLocale(lang);

    await setLanguagePref(normalizedLang);

    if (userStore.user.id) {
      if (userStore.user.language !== normalizedLang) {
        await api.users.setLanguage(normalizedLang);
      }
    }

    userStore.user.language = normalizedLang;
    locale.value = normalizedLang;
    router.replace({
      query: { ...router.currentRoute.value.query, lang: normalizedLang },
    });
  }

  function getAvailableLocales() {
    return Object.entries(locales)
      .filter(([_, v]) => !v.disabled)
      .map(([_, v]) => ({
        value: v.value,
        title: v.title,
      }));
  }
  function getDisabledLocales() {
    return Object.entries(locales)
      .filter(([_, v]) => v.disabled)
      .map(([_, v]) => ({
        value: v.value,
        title: v.title,
      }));
  }

  return {
    t,
    tm,
    locale: locale as WritableComputedRef<SourceLang>,
    setLocale,
    getAvailableLocales,
    getDisabledLocales,
  };
}
