import { Capacitor } from '@capacitor/core';
import { ref } from 'vue';

const isNative = Capacitor.isNativePlatform();
const isInstallAppModalVisible = ref(false);

export function useAppOnlyFeature() {
  const handleAppOnlyRouteNavigation = () => {
    if (!isNative) {
      isInstallAppModalVisible.value = true;
      return false;
    }
    return true;
  };

  const closeInstallAppModal = () => (isInstallAppModalVisible.value = false);

  return {
    isInstallAppModalVisible,
    handleAppOnlyRouteNavigation,
    closeInstallAppModal,
  };
}
