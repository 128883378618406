import {
  AchievementI,
  FeedPublicationI,
  SourceLang,
  UserAchievementI,
  UserCourseI,
  UserI,
} from '@/types';
import { defineStore } from 'pinia';
import {
  useIsLoggedPrefs,
  useUserIdPrefs,
  useUserDataPrefs,
  useLanguagePrefs,
} from '@/stores/preferences';
import { RouteLocation } from 'vue-router';
import api from '@/api';

const { get: getLangPref } = useLanguagePrefs();
const localePref = await getLangPref();

interface UserStoreI {
  user: UserI;
  isLogged: boolean;
  isLoadedUser: boolean;
  publicationMessageFromSearchRequest: string;
  routeTargetForSuccessAuth: RouteLocation | null;
  createdPublication: null | FeedPublicationI;
  isShowRequestNotifications: boolean;
  isShowCompletedAchievement: boolean;
  completedAchievement: null | AchievementI;
  userDataCompletedAchievement: null | UserAchievementI;
  unsendedInputValues: {
    publicationTitle: string;
    publicationText: string;
    commentText: string;
    commentPublicationId: string;
  };
}

const locale: SourceLang =
  (localePref as SourceLang | null) ||
  (navigator.language.split('-')[0] as SourceLang);

export const USER_INITIAL_DATA: UserI = {
  alias: '',
  email: '',
  id: '',
  themes: [],
  verified: false,
  passwordResetCount: 0,
  language: locale,
};

const cachedUserData = JSON.parse((await useUserDataPrefs().get()) || '{}');
cachedUserData.language = locale;

export const useUserStore = defineStore('user', {
  state: () => {
    return {
      user: cachedUserData.id ? cachedUserData : USER_INITIAL_DATA,
      isLogged: !!cachedUserData.id,
      isLoadedUser: false,
      publicationMessageFromSearchRequest: '',
      routeTargetForSuccessAuth: null,
      createdPublication: null,
      isShowRequestNotifications: false,
      isShowCompletedAchievement: false,
      completedAchievement: null,
      userDataCompletedAchievement: null,
      unsendedInputValues: {
        publicationTitle: '',
        publicationText: '',
        commentText: '',
        commentPublicationId: '',
      },
    } as UserStoreI;
  },
  actions: {
    setIsLoadedUser(isLoaded: boolean) {
      this.isLoadedUser = isLoaded;
    },
    setUser(u: UserI) {
      this.user = u;
      this.isLogged = true;
      const { set: setIsLogged } = useIsLoggedPrefs();
      const { set: setUserId } = useUserIdPrefs();
      const { set: setUserData } = useUserDataPrefs();
      setIsLogged('yes');
      setUserId(this.user.id);
      setUserData(JSON.stringify(this.user));
    },
    async unsetUser() {
      this.isLogged = false;
      const l = this.user.language;
      this.user = { ...USER_INITIAL_DATA };
      this.user.language = l || USER_INITIAL_DATA.language;
      const { set: setIsLogged } = useIsLoggedPrefs();
      const { set: setUserId } = useUserIdPrefs();
      const { set: setUserData } = useUserDataPrefs();
      const { set: setLanguagePref } = useLanguagePrefs();
      setIsLogged('');
      setUserId('');
      setUserData('');
      setLanguagePref('');

      try {
        if (this.user.id) {
          await api.users.removeFcmTokenByUserId(this.user.id);
        }
      } catch (e) {
        console.error(e);
      }
    },
    setLocale(lang: SourceLang | string) {
      console.log('setting locale', lang);
      this.user.language = lang as SourceLang;
    },
    addTheme(themeId: string) {
      if (this.user.themes) {
        this.user.themes.push(themeId);
      } else {
        this.user.themes = [themeId];
      }
    },
    removeTheme(themeId: string) {
      this.user.themes =
        this.user.themes?.filter((tId) => tId !== themeId) || [];
    },
    addBlockedUser(id: string) {
      if (this.user.blockedUserIds?.length) {
        this.user.blockedUserIds.push(id);
      } else {
        this.user.blockedUserIds = [id];
      }
    },
    removeBlockedUser(id: string) {
      if (this.user.blockedUserIds) {
        this.user.blockedUserIds = this.user.blockedUserIds.filter(
          (s: string) => s !== id
        );
      }
    },
    addBlockedRedditUser(id: string) {
      if (this.user.blockedRedditUsernames?.length) {
        this.user.blockedRedditUsernames.push(id);
      } else {
        this.user.blockedRedditUsernames = [id];
      }
    },
    removeBlockedRedditUser(id: string) {
      if (this.user.blockedRedditUsernames) {
        this.user.blockedRedditUsernames =
          this.user.blockedRedditUsernames.filter((s: string) => s !== id);
      }
    },
    setPublicationMessage(message: string) {
      this.publicationMessageFromSearchRequest = message;
    },
    setRouteTargetForSuccessAuth(to: RouteLocation | null) {
      this.routeTargetForSuccessAuth = to;
    },
    setCreatedPublication(p: FeedPublicationI | null) {
      this.createdPublication = p;
    },
    setTherapyCourse(course: UserCourseI) {
      if (!this.user.therapyCourses) {
        this.user.therapyCourses = [course];
      } else {
        this.user.therapyCourses.push(course);
      }
    },
    setTherapyCourses(courses: UserCourseI[]) {
      this.user.therapyCourses = courses;
    },
    removeTherapyCourse(course: UserCourseI) {
      if (this.user.therapyCourses) {
        this.user.therapyCourses = this.user.therapyCourses.filter(
          (c) => c.id !== course.id
        );
      }
    },
    setIsShowRequestNotifications(isShow: boolean) {
      this.isShowRequestNotifications = isShow;
    },
    setIsCompletedAchievement(isShow: boolean) {
      this.isShowCompletedAchievement = isShow;
    },
    setUnsendedInputValue(
      key: keyof UserStoreI['unsendedInputValues'],
      value: string
    ) {
      this.unsendedInputValues[key] = value;
    },
  },
});
