import {
    doc,
    getFirestore,
    getDocs,
    query,
    where,
    collection, setDoc, deleteDoc
} from 'firebase/firestore';
import { BannedEmailI } from '@/types';

export function createBannedEmails() {
    const db = getFirestore();
    const bannedEmailsPath = 'banned-emails';

    return {
        async getExistEmail(email: string): Promise<BannedEmailI | null> {
            const bannedEmailCollection = collection(db, bannedEmailsPath);
            const bannedEmailQuery = query(bannedEmailCollection, where('email', '==', email));
            const querySnapshot = await getDocs(bannedEmailQuery);
            let bannedEmail = null;
            querySnapshot.forEach(doc => {
                bannedEmail = doc.data() as BannedEmailI;
                bannedEmail.id = doc.id;
            });
            return bannedEmail;
        },
        async create(email: string) {
            if (await this.getExistEmail(email)) {
                return
            }
            const bannedEmailCollection = collection(db, bannedEmailsPath);
            const bannedEmailRef = doc(bannedEmailCollection);
            await setDoc(bannedEmailRef, { email });
        },
        async delete(email: string) {
            const bannedEmail = await this.getExistEmail(email)
            if (!bannedEmail?.id) {
                return
            }
            const bannedEmailRef = doc(db, bannedEmailsPath, bannedEmail.id);
            await deleteDoc(bannedEmailRef);
        }
    }
}
