import { createAuth } from './auth';
import { createPublications } from './publications';
import { createUsers } from './users';
import { createComments } from './comments';
import { createThemes } from './themes';
import { createStories } from './stories';
import { createNotifications } from './notifications';
import { createBannedEmails } from './bannedEmails';
import { createExpertRequests } from './expertRequests';
import { createCourses } from './courses';
import { createAchievements } from './achievements';
import { createDailyCheckReports } from './dailyCheckReports';
import { createPurchaseProducts } from './purchase-products';
import { createHelpItems } from './helpItems';
import { createUserRates } from './user-rates';
import { createTests } from './tests';
import { createUserTests } from './userTests';

// import { SearchClient } from 'typesense';

// const searchClient = new SearchClient({
//   nodes: [
//     {
//       host: 'typesen.feelyou.com',
//       port: 443,
//       protocol: 'https',
//     },
//   ],
//   apiKey: 'iiOeePGZcQC9TSa1arW231d5ujcvjdzU',
// });

export default {
  auth: createAuth(),
  users: createUsers(),
  publications: createPublications(),
  comments: createComments(),
  themes: createThemes(),
  stories: createStories(),
  notifications: createNotifications(),
  bannedEmails: createBannedEmails(),
  expertRequests: createExpertRequests(),
  courses: createCourses(),
  achievements: createAchievements(),
  dailyCheckReports: createDailyCheckReports(),
  purchaseProducts: createPurchaseProducts(),
  helpItems: createHelpItems(),
  userRates: createUserRates(),
  tests: createTests(),
  userTests: createUserTests(),
};
