<template>
  <ion-button
    :class="[
      'base-button',
      { 'base-button--primary': props.primary },
      { 'base-button--secondary': props.secondary },
      { 'base-button--blank': props.blank },
      { 'base-button--white': props.white },
      { 'base-button--round': props.rounded },
    ]"
    v-bind="$attrs"
    :disabled="props.disabled || props.loading"
    @click="emit('click', $event)"
  >
    <slot v-if="!props.loading" />
    <ion-spinner v-else name="lines-sharp-small" />
  </ion-button>
</template>

<script setup lang="ts">
import { IonButton, IonSpinner } from '@ionic/vue';

const emit = defineEmits(['click']);
const props = defineProps({
  primary: {
    type: Boolean,
    default: true,
  },
  secondary: {
    type: Boolean,
    default: false,
  },
  blank: {
    type: Boolean,
    default: false,
  },
  loading: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  white: {
    type: Boolean,
    default: false,
  },
  rounded: {
    type: Boolean,
    default: false,
  },
});
</script>

<style lang="scss" scoped>
.base-button {
  width: 100%;
  font-weight: 600;
  --border-radius: 90px;
  --background-activated: unset;
  --background-hover: unset;
  --padding-top: 12px;
  --padding-bottom: 12px;
  white-space: unset !important;

  &--primary {
    --background: #6289ff;
    --color: #fff;
    --background-activated: var(--ion-color-primary-shade);
  }

  &--secondary {
    --background: #ebedfb;
    --color: #172659;
    --background-activated: var(--ion-color-secondary-shade);
  }

  &--blank {
    --background: transparent !important;
    --padding-top: 0px !important;
    --padding-bottom: 0px;
    --padding-start: 0px;
    --padding-end: 0px;
    --color: var(--ion-color-primary);
  }

  &--white {
    --background: #ebedfb !important;
    --color: #000;
  }

  &--round {
    --border-radius: 100%;
    width: 34px;
    height: 34px;
    min-height: 34px;
    --padding-top: 0px;
    --padding-bottom: 0px;
    --padding-start: 8px;
    --padding-end: 8px;
  }
}
</style>
