import {
  doc,
  getFirestore,
  setDoc,
  getDoc,
  getDocs,
  query,
  where,
  collection,
  arrayUnion,
  updateDoc,
  arrayRemove,
  Timestamp,
} from 'firebase/firestore';
import { UserCourseI, UserI, SourceLang } from '@/types';
import { useUserStore } from '@/stores/user';
import { useUserIdPrefs } from '@/stores/preferences';
import {
  getStorage,
  ref,
  uploadBytes,
  getDownloadURL,
  listAll,
  deleteObject,
} from 'firebase/storage';
import { useRateCourseModal } from '@/composables/useRateCourseModal';

export function createUsers() {
  const db = getFirestore();
  const storage = getStorage();
  const usersPath = 'users';

  return {
    create: (params: UserI) => {
      return setDoc(doc(db, usersPath, params.id), params);
    },
    async update(id: string, params: Partial<UserI>) {
      const userRef = doc(db, usersPath, id);
      return await updateDoc(userRef, params);
    },
    async getById(id: string) {
      const userRes = await getDoc(doc(db, usersPath, id));
      return userRes.data() as UserI;
    },
    async getByIds(ids: string[]): Promise<UserI[]> {
      console.log('user ids', ids);
      const users: UserI[] = [];
      const usersRef = collection(db, usersPath);
      const q = query(usersRef, where('id', 'in', ids));
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((u) => {
        const user = u.data() as UserI;
        users.push(user);
      });
      return users;
    },
    async addTheme(themeId: string) {
      const userStore = useUserStore();
      const userId = userStore.user.id;
      const userDoc = doc(db, usersPath, userId);
      await updateDoc(userDoc, {
        themes: arrayUnion(themeId),
      });
    },
    async removeTheme(themeId: string) {
      const userStore = useUserStore();
      const userId = userStore.user.id;
      const userDoc = doc(db, usersPath, userId);
      await updateDoc(userDoc, {
        themes: arrayRemove(themeId),
      });
    },
    async blockById(id: string) {
      const userStore = useUserStore();
      const userId = userStore.user.id;
      const userDoc = doc(db, usersPath, userId);
      await updateDoc(userDoc, {
        blockedUserIds: arrayUnion(id),
      });
    },
    async unblockById(id: string) {
      const userStore = useUserStore();
      const userId = userStore.user.id;
      const userDoc = doc(db, usersPath, userId);
      await updateDoc(userDoc, {
        blockedUserIds: arrayRemove(id),
      });
    },
    async blockByRedditUsername(id: string) {
      const userStore = useUserStore();
      const userId = userStore.user.id;
      const userDoc = doc(db, usersPath, userId);
      await updateDoc(userDoc, {
        blockedRedditUsernames: arrayUnion(id),
      });
    },
    async unblockByRedditUsername(id: string) {
      const userStore = useUserStore();
      const userId = userStore.user.id;
      const userDoc = doc(db, usersPath, userId);
      await updateDoc(userDoc, {
        blockedRedditUsernames: arrayRemove(id),
      });
    },
    async banUser(userId: string) {
      const userDoc = doc(db, usersPath, userId);
      await updateDoc(userDoc, {
        isBanned: true,
      });
    },
    async unbanUser(userId: string) {
      const userDoc = doc(db, usersPath, userId);
      await updateDoc(userDoc, {
        isBanned: false,
      });
    },
    async uploadPhotoByUrl(url: string) {
      await this.deleteAllUserPhotos();

      const userStore = useUserStore();
      userStore.setUser({ ...userStore.user, avatarUrl: url });

      const img = await fetch(url);
      const imgBlob = await img.blob();
      const filename = `${img.url.split('/')[3]}.${imgBlob.type.split('/')[1]}`;
      const imageRef = ref(storage, `users/${userStore.user.id}/${filename}`);

      await uploadBytes(imageRef, imgBlob);
      const avatarUrl = await getDownloadURL(imageRef);

      await this.update(userStore.user.id, { avatarUrl });
    },
    async deletePhoto() {
      const userStore = useUserStore();
      if (!userStore.user.avatarUrl) {
        return;
      }

      userStore.setUser({ ...userStore.user, avatarUrl: null });
      await this.update(userStore.user.id, { avatarUrl: null });

      await this.deleteAllUserPhotos();
    },
    async deleteAllUserPhotos() {
      const userStore = useUserStore();
      const listRef = ref(storage, `users/${userStore.user.id}`);
      const photos = await listAll(listRef);
      for await (const item of photos.items) {
        const imageRef = ref(
          storage,
          `users/${userStore.user.id}/${item.name}`
        );
        await deleteObject(imageRef);
      }
    },
    async addCourseToUserLibrary(course: UserCourseI) {
      const { get: getUserId } = useUserIdPrefs();
      const userId = (await getUserId()) as string;

      const userDoc = doc(db, usersPath, userId);

      await updateDoc(userDoc, {
        therapyCourses: arrayUnion(course),
      });
    },
    async setIsFinishedCourseVideo({
      courseId,
      videoId,
      isFinished,
    }: {
      courseId: string;
      videoId: string;
      isFinished: boolean;
    }) {
      const userStore = useUserStore();
      const userId = userStore.user.id;
      const userTherapyCourses = userStore.user.therapyCourses;
      const { showRateCourseModal } = useRateCourseModal();

      if (userTherapyCourses) {
        const updatedTherapyCourses: UserCourseI[] = [
          ...userTherapyCourses,
        ].map((c) => {
          if (c.id === courseId) {
            c.videoItems = c.videoItems.map((v) => {
              if (v.id === videoId) {
                v.isFinished = isFinished;
                if (isFinished) {
                  const now = new Date();
                  now.setHours(0, 0, 0, 0);
                  v.finishDate = Timestamp.fromDate(now);
                }
              }
              return v;
            });

            const hasUnfinishedVideos = c.videoItems.find((v) => !v.isFinished);
            if (!hasUnfinishedVideos && !c.isFinished) {
              c.isFinished = true;
              showRateCourseModal();
            }
          }
          return c;
        });

        const userDoc = doc(db, usersPath, userId);

        await updateDoc(userDoc, {
          therapyCourses: updatedTherapyCourses,
        });
        userStore.setTherapyCourses(updatedTherapyCourses);
      }
    },
    async setStoppedTimestampBy({
      time,
      courseId,
      videoId,
    }: {
      time: number;
      videoId: string;
      courseId: string;
    }) {
      const userStore = useUserStore();
      const userId = userStore.user.id;
      const userTherapyCourses = userStore.user.therapyCourses;

      if (userTherapyCourses) {
        const updatedTherapyCourses: UserCourseI[] = [
          ...userTherapyCourses,
        ].map((c) => {
          if (c.id === courseId) {
            c.videoItems = c.videoItems.map((v) => {
              if (v.id === videoId) {
                v.stoppedTimestamp = time;
              }
              return v;
            });
          }
          return c;
        });

        const userDoc = doc(db, usersPath, userId);

        await updateDoc(userDoc, {
          therapyCourses: updatedTherapyCourses,
        });
        userStore.setTherapyCourses(updatedTherapyCourses);
      }
    },
    async removeCourseById(courseId: string) {
      const userStore = useUserStore();
      const userId = userStore.user.id;
      const userTherapyCourses = userStore.user.therapyCourses;

      if (userTherapyCourses) {
        const updatedTherapyCourses: UserCourseI[] = userTherapyCourses.filter(
          (c) => c.id !== courseId
        );
        const userDoc = doc(db, usersPath, userId);

        await updateDoc(userDoc, {
          therapyCourses: updatedTherapyCourses,
        });
        userStore.setTherapyCourses(updatedTherapyCourses);
      }
    },
    async setFcmToken(token: string) {
      const userStore = useUserStore();
      const userId = userStore.user.id;
      if (userId) {
        const userDoc = doc(db, usersPath, userId);
        await updateDoc(userDoc, {
          fcmToken: token,
        });
      }
    },
    async setLanguage(language: SourceLang) {
      const userStore = useUserStore();
      const userId = userStore.user.id;
      if (userId) {
        const userDoc = doc(db, usersPath, userId);
        await updateDoc(userDoc, {
          language,
        });
      }
    },
    async removeFcmTokenByUserId(userId: string) {
      if (userId) {
        const userDoc = doc(db, usersPath, userId);
        await updateDoc(userDoc, {
          fcmToken: null,
        });
      }
    },
    async checkIfUserIsModeratorById(id: string) {
      const user = await this.getById(id);
      return user?.isModerator;
    },
  };
}
