<template>
  <base-modal
    :is-opened="props.isOpened"
    is-mobile-center
    is-hide-close
    :is-not-close-outside="!isClose"
    class="tutorial-modal"
  >
    <h2 class="tutorial-modal__title" v-html="props.title" />
    <div class="tutorial-modal__text">{{ props.text }}</div>
    <div class="tutorial-modal__buttons">
      <button
        type="button"
        class="tutorial-modal__button tutorial-modal__button--skip"
        @click="handleSkip"
      >
        {{ t('skip') }}
      </button>
      <button type="button" class="tutorial-modal__button" @click="handleNext">
        {{ props.isLast ? t('letsStart') : t('next') }}
      </button>
    </div>
  </base-modal>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { useTutorialShowedPrefs } from '@/stores/preferences';
import { useInternalization } from '@/composables/useInternalization';

import BaseModal from '@/components/modals/BaseModal.vue';

const props = defineProps<{
  isOpened: boolean;
  title: string;
  text: string;
  isLast?: boolean;
}>();

const emit = defineEmits(['close']);
const { t } = useInternalization();

const isClose = ref(false);

function handleSkip() {
  const { set } = useTutorialShowedPrefs();
  set('yes');
  handleClose();
}

function handleNext() {
  if (props.isLast) {
    const { set } = useTutorialShowedPrefs();
    set('yes');
  }
  handleClose(true);
}

function handleClose(isNext?: boolean) {
  isClose.value = true;
  emit('close', isNext);
}
</script>

<style lang="scss" scoped>
.tutorial-modal {
  &__title {
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    color: #172659;
    margin: 0 auto 0 0;

    :deep(b) {
      font-weight: 600;
      color: #6289ff;
    }
  }

  &__text {
    font-size: 15px;
    line-height: 19px;
    color: #172659;
    margin-top: 8px;
    margin-right: auto;
  }

  &__buttons {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 8px;
    margin-top: 16px;
  }

  &__button {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.32px;
    background-color: #6288ff;
    color: #fff;
    border-radius: 20px;
    padding: 8px 16px;

    &--skip {
      background-color: transparent;
      color: #172659;
    }
  }
}

:deep(.modal__container) {
  padding: 24px;

  @media (max-width: 720px) {
    padding: 20px;
  }
}
</style>
