export default {
  en: {
    userFAQ: {
      sections: [
        {
          text: '<p>To delete your account:<br /> <br /> - Go to the "Profile" section.<br />- Press "Edit profile".<br />- Click on the "Delete" button to delete your account.<br /> <br /> This will permanently delete all of your user data, including your email, username, password, and any content (posts, comments, mood tags, and course progress) you have created on the platform.</p>',
          title: 'How to delete an account?',
          name: 'how-to-delete-account',
        },
        {
          text: `Cognitive-behavioral therapy, known as CBT, operates on the premise that our thoughts, feelings, and actions are interconnected. Our thoughts influence our emotions, which then impact our behaviors. For instance, if we believe that someone dislikes us, we might feel anxious around them and avoid interactions. This anxiety can trigger more negative thoughts.
					
					In various situations, we have numerous automatic thoughts that we may not consciously notice. These thoughts stem from ingrained beliefs about ourselves, others, and the world. During times of stress, negative beliefs like "I'm not good enough" or "The world is unsafe" surface.
					
					CBT aims to identify these beliefs and gradually replace them with more rational and supportive ones. This process is based on the following principles:
					
					- Although our beliefs may seem plausible, they do not always correspond to reality.
					-  These beliefs, which are inaccurate and negative, cause emotional distress.
					- By challenging and correcting these thinking errors, we can change our perception of situations and influence our emotional well-being.
					
					One of the strengths of CBT is its evidence-based approach. It integrates principles from behaviorism, which focuses on behavior, and cognitivism, which explores thought processes. Research has shown that a person's behavior is influenced by external reinforcement, while cognitive psychology emphasizes the importance of identifying thoughts and ideas that influence behavior.
					
					CBT has been proven effective in treating various mental health issues, such as depression, anxiety disorders, and phobias. Studies, including those by the World Health Organization, support its efficacy. Combining CBT with medication has also shown positive outcomes in severe cases, as highlighted in research published in the World Psychiatric Association journal in 2020.
					
					Many CBT techniques can be practiced independently. The exercises provided in our courses' "Practical Exercises" and "First Aid" sections are rooted in CBT principles. Through these techniques, you can analyze the underlying thoughts behind your emotions, assess their validity, and learn to release them without letting them dictate your emotional state.	
					`,
          title: 'How does CBT work?',
          name: 'how-does-cbt-work',
        },
      ],
    },
  },
  ru: {
    userFAQ: {
      sections: [
        {
          text: '<p>Чтобы удалить свою учетную запись:<br /> <br /> - Перейдите в раздел "Профиль".<br />- Нажмите "Редактировать профиль".<br />- Нажмите на кнопку "Удалить", чтобы удалить свою учетную запись.<br /> <br /> Это приведет к необратимому удалению всех ваших пользовательских данных, включая ваш адрес электронной почты, имя пользователя, пароль и любой контент (посты, комментарии, теги настроения и ход прохождения курса), который вы создали на платформе.</p>',
          title: 'Как удалить аккаунт',
          name: 'how-to-delete-account',
        },
        {
          text: `Когнитивно-поведенческая терапия, или сокращенно КПТ, основана на идее, что наши мысли, чувства и поведение напрямую взаимосвязаны. То, о чем мы думаем, формирует наши эмоции, которые, в свою очередь, влияют на наши действия. Например, если мы убеждены, что кто-то к нам плохо относится, мы можем начать бояться общения с этим человеком и избегать его. При этом чувство страха может порождать новые деструктивные мысли. 

					В разных ситуациях у нас возникает множество "автоматических" мыслей, которые мы даже не осознаем. Они проистекают из усвоенных нами ранее убеждений о себе, мире и окружающих. В периоды стресса или перегруженности эмоциями на первый план выходят именно негативные представления, такие как “Я недостаточно хорош" или "Мир опасен".
					
					КПТ помогает выявлять подобные убеждения и постепенно заменять их более рациональными и поддерживающими. Это основано на понимании основных принципов:
					
					– Хотя наши убеждения кажутся правдоподобными, далеко не всегда они соответствуют реальности.
					– Именно убеждения, не соответствующие реальности и имеющие негативный фон, вызывают психологический дискомфорт.
					– Мы способны проверять привычные убеждения на наличие ошибок мышления (несоответствие реальности), исправлять их, тем самым меняя восприятие ситуаций и влияя на свое эмоциональное состояние в лучшую сторону.
					
					Преимущество КПТ в том, что в ее основе лежит исключительно научный подход. Он объединяет принципы двух направлений – бихевиоризма, изучающего поведение, и когнитивизма, изучающего мыслительные процессы. Исследования бихевиористов показали, что поведение человека во многом определяется тем, какие действия подкрепляются извне. В свою очередь когнитивизм делает акцент на важности выявления мыслей и идей, влияющих на поведение.  
					
					Эффективность КПТ при лечении депрессии, тревожных расстройств, фобий и многих других проблем подтверждена большим количеством исследований, включая исследования, проведенные Всемирной организацией здравоохранения. Существуют также доказательства, что КПТ в дополнение к медикаментозному лечению помогает в некоторых тяжёлых случаях лучше, чем исключительно медикаментозная терапия.  Например, об этом свидетельствует анализ исследований в отношении сочетания психотерапии и фармакологии в лечении депрессии, приведенный в журнале Всемирной психиатрической ассоциации в 2020 году. 
					
					Многие техники когнитивно-поведенческой терапии можно применять самостоятельно. Упражнения, которые мы предлагаем на наших курсах в разделах «Практические упражнения» и «Скорая помощь», также основаны на принципах КПТ. Так, вы можете найти техники, помогающие проанализировать, какие мысли стоят за конкретным чувством и проверить эти мысли на реалистичность. А также отпустить эти мысли, не позволяя им продолжать контролировать наше эмоциональное состояние. 
					`,
          title: 'Как работает КПТ?',
          name: 'how-does-cbt-work',
        },
      ],
    },
  },
};
