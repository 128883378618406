<template>
  <base-modal
    :is-opened="props.isOpened"
    class="install-app-modal"
    @close="close"
  >
    <div class="install-app-modal__title">
      This is a FREE feature exclusively available in our app
    </div>
    <div class="install-app-modal__text">
      Download our app to gain a free access to a variety of tools, including
      therapist advice, community support, self-therapy videos, and much more.
    </div>
    <div class="install-app-modal__buttons">
      <img src="/assets/app-store-banner.png" @click="openAppleStore" />
      <img src="/assets/google-play-banner.png" @click="openGoogleStore" />
    </div>
  </base-modal>
</template>

<script setup lang="ts">
import BaseModal from '@/components/modals/BaseModal.vue';
import { Browser } from '@capacitor/browser';

const props = defineProps<{
  isOpened: boolean;
}>();

const emit = defineEmits(['close']);

function close() {
  if (props.isOpened) {
    emit('close');
  }
}

function openAppleStore() {
  Browser.open({
    url: 'https://apps.apple.com/app/feelyou/id1673720647',
  });
  close();
}
function openGoogleStore() {
  Browser.open({
    url: 'https://play.google.com/store/apps/details?id=io.feelyou.app',
  });

  close();
}
</script>

<style lang="scss" scoped>
.install-app-modal {
  --width: 400px;
  color: #172659;
  &__title {
    font-size: 18px;
    line-height: 24px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 1em;
  }
  &__text {
    font-size: 15px;
    line-height: 24px;
    font-weight: 400;
    text-align: center;
    margin-bottom: 1em;
  }
  &__buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1em;

    & > :not(:last-child) {
      margin-right: 10px;
    }

    img {
      max-width: 140px;
      cursor: pointer;
    }
  }
}
</style>
