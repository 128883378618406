import {
  arrayRemove,
  arrayUnion,
  collection,
  deleteDoc,
  doc,
  DocumentData,
  FieldPath,
  getDoc,
  getDocs,
  getFirestore,
  increment,
  limit,
  orderBy,
  query,
  QueryConstraint,
  QueryDocumentSnapshot,
  setDoc,
  startAfter,
  Timestamp,
  updateDoc,
  where,
} from 'firebase/firestore';
import { ApiFetchParamsI, PublicationI, ReportReasonI } from '@/types';
import { useUserIdPrefs } from '@/stores/preferences';
// import { SearchClient } from 'typesense';
// import { SearchParams } from 'typesense/lib/Typesense/Documents';
import { getDownloadURL, getStorage, ref, uploadBytes } from 'firebase/storage';
import { useUserStore } from '@/stores/user';

const QUERY_LIMIT = 10;

// interface PublicationsFactoryParamsI {
//   searchClient?: SearchClient;
// }

export function createPublications() {
  // const searchClient = params?.searchClient;
  const db = getFirestore();
  const storage = getStorage();
  const publicationsPath = 'publications';
  const reportReasonsPath = 'report-reasons';
  const publicationReportsPath = 'publication-reports';
  const publicationsDeletedPath = 'publications-deleted';
  const { get: getUserId } = useUserIdPrefs();

  let lastVisiblePubRef: QueryDocumentSnapshot<DocumentData> | null = null;

  return {
    create: async (
      params: Omit<PublicationI, 'publicationId' | 'sourceLang'>,
      id?: string
    ) => {
      const publicationCollection = collection(db, publicationsPath);
      const publicationRef = id
        ? doc(publicationCollection, id)
        : doc(publicationCollection);
      let imgUrl = '';
      const user = useUserStore().user;

      const publication: PublicationI = {
        ...params,
        publicationId: publicationRef.id,
        sourceLang: user.language || 'en',
      };

      if (publication.content.images?.length) {
        imgUrl = await getDownloadURL(
          ref(storage, publication.content.images[0])
        );
        if (imgUrl) {
          publication.content.images = [imgUrl];
        }
      }

      await setDoc(publicationRef, publication);
      return publication;
    },
    edit: async (params: PublicationI) => {
      const publicationRef = doc(db, publicationsPath, params.publicationId);
      //const imgUrl = '';

      const publication = {
        title: params.title,
        content: params.content,
        isEdited: true,
      };

      // if (publication.content.images?.length) {
      //   imgUrl = await getDownloadURL(
      //     ref(storage, publication.content.images[0])
      //   );
      //   if (imgUrl) {
      //     publication.content.images = [imgUrl];
      //   }
      // }

      await updateDoc(publicationRef, publication);
      return publication;
    },
    async update(id: string, params: Partial<PublicationI>) {
      const publicationRef = doc(db, publicationsPath, id);
      return await updateDoc(publicationRef, params);
    },
    async delete(params: PublicationI) {
      const publicationDeletedCollection = collection(
        db,
        publicationsDeletedPath
      );
      const publicationDeletedRef = doc(publicationDeletedCollection);
      const publication: PublicationI = { ...params };
      await setDoc(publicationDeletedRef, publication);

      const publicationRef = doc(db, publicationsPath, params.publicationId);
      await deleteDoc(publicationRef);
    },
    async addSpecialistAnsweredUser(
      publicationId: string,
      specialists: PublicationI['answeredSpecialists']
    ): Promise<{ userId: string; avatarUrl?: string; name: string } | null> {
      const publicationRef = doc(db, publicationsPath, publicationId);
      const spec = specialists?.length ? specialists[0] : null;

      if (!spec) {
        return null;
      }
      if (!spec.avatarUrl) {
        delete spec.avatarUrl;
      }
      console.log(spec);

      await updateDoc(publicationRef, {
        answeredSpecialists: arrayUnion(spec),
      });
      return spec;
    },
    async searchByText(
      fieldPath: string | FieldPath,
      value: string
    ): Promise<PublicationI[]> {
      const querySnapshot = await getDocs(
        query(
          collection(db, publicationsPath),
          where(fieldPath, '>=', value),
          where(fieldPath, '<=', '~')
        )
      );
      const publications: PublicationI[] = [];
      querySnapshot.forEach((doc) => {
        const publication = doc.data() as PublicationI;
        publications.push(publication);
      });

      return publications;
    },
    async getById(id: string): Promise<PublicationI> {
      const querySnapshot = await getDoc(doc(db, publicationsPath, id));
      return querySnapshot.data() as PublicationI;
    },
    async getByIds(ids: string[]) {
      const publications: PublicationI[] = [];
      const querySnapshot = await getDocs(
        query(
          collection(db, publicationsPath),
          where('publicationId', 'in', ids)
        )
      );
      querySnapshot.forEach((p) => {
        const publication = p.data() as PublicationI;
        publications.push(publication);
      });
      return publications;
    },
    async getMyNotPublic(): Promise<PublicationI[]> {
      const docRef = collection(db, publicationsPath);
      const userStore = useUserStore();
      console.log('userStore.user.id', userStore.user.id);
      if (!userStore.user.id) {
        return [];
      }

      const params = query(
        docRef,
        where('sourceLang', '==', userStore.user.language || 'en'),
        where('authorId', '==', userStore.user.id),
        where('date', '>', Timestamp.now()),
        orderBy('date', 'desc'),
        limit(100)
      );

      const querySnapshot = await getDocs(params);
      const publications: PublicationI[] = [];
      querySnapshot.forEach(async (doc) => {
        const publication = doc.data() as PublicationI;
        publications.push(publication);
      });
      return publications;
    },
    async getAll(): Promise<PublicationI[]> {
      const docRef = collection(db, publicationsPath);
      const userStore = useUserStore();
      let params;

      if (userStore.isLogged) {
        params = query(
          docRef,
          orderBy('date', 'desc'),
          where('authorId', 'not-in', userStore.user.blockedUserIds),
          where('sourceLang', '==', userStore.user.language || 'en')
        );
      } else {
        params = query(docRef, orderBy('date', 'desc'));
      }
      const userId = (await getUserId()) as string;

      const querySnapshot = await getDocs(params);
      const publications: PublicationI[] = [];
      querySnapshot.forEach(async (doc) => {
        const publication = doc.data() as PublicationI;
        publications.push(publication);
      });

      return publications.filter((p) => !p.reportingUserIds?.includes(userId));
    },
    async getAllWithLimit(p?: ApiFetchParamsI, userIdParam?: string) {
      const docRef = collection(db, publicationsPath);
      const userId = (await getUserId()) as string;
      const userStore = useUserStore();

      const isBlockedSomeUsers =
        userStore.isLogged && userStore.user.blockedUserIds?.length;
      const isBlockedSomeRedditUsers =
        userStore.isLogged && userStore.user.blockedRedditUsernames?.length;

      console.log('loading all with limit');

      let params: QueryConstraint[] = [];
      if ((isBlockedSomeRedditUsers || isBlockedSomeUsers) && !userIdParam) {
        if (isBlockedSomeRedditUsers && isBlockedSomeUsers) {
          const userBlockedArr = userStore.user.blockedUserIds?.length
            ? userStore.user.blockedUserIds
            : [];
          const redditUserBlockedArr = userStore.user.blockedRedditUsernames
            ?.length
            ? userStore.user.blockedRedditUsernames
            : [];
          const fArr = [...userBlockedArr, redditUserBlockedArr];

          params = [
            ...params,
            orderBy('redditUsername, authorId', 'asc'),
            where('redditUsername, authorId', 'not-in', fArr),
          ];
        } else if (isBlockedSomeUsers) {
          params = [
            ...params,
            orderBy('authorId', 'asc'),
            where('authorId', 'not-in', userStore.user.blockedUserIds),
          ];
        } else if (isBlockedSomeRedditUsers) {
          params = [
            ...params,
            orderBy('redditUsername', 'asc'),
            where(
              'redditUsername',
              'not-in',
              userStore.user.blockedRedditUsernames
            ),
          ];
        }
      } else if (userIdParam) {
        params = [...params, where('authorId', '==', userIdParam)];
      } else {
        params = [...params, orderBy('date', 'desc')];
      }

      if (p?.favoriteUserId) {
        params = [
          ...params,
          where('favoritedUserIds', 'array-contains', p.favoriteUserId),
        ];
      }

      params = [
        ...params,
        where('sourceLang', '==', userStore.user?.language || 'en'),
        where('date', '<', Timestamp.now()),
        limit(QUERY_LIMIT),
      ];
      console.log('post params', params);

      if (!p?.fromStart && lastVisiblePubRef) {
        params = [...params, startAfter(lastVisiblePubRef)];
      }

      const querySnapshot = await getDocs(query(docRef, ...params));
      const publications: PublicationI[] = [];
      querySnapshot.forEach(async (doc) => {
        const publication = doc.data() as PublicationI;
        publications.push(publication);
      });

      const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
      const isLastPage = querySnapshot.size < QUERY_LIMIT;
      lastVisiblePubRef = isLastPage ? null : lastVisible;

      let result = publications.filter(
        (p) => !p.reportingUserIds?.includes(userId)
      );
      if (isBlockedSomeRedditUsers || isBlockedSomeUsers) {
        result = result.sort(
          (a: PublicationI, b: PublicationI) => Number(b.date) - Number(a.date)
        );
      }
      return {
        data: result,
        isLastPage,
      };
    },
    async getByThemeId(id: string, p?: ApiFetchParamsI) {
      const docRef = collection(db, publicationsPath);
      const userId = (await getUserId()) as string;
      const userStore = useUserStore();

      console.log('p', p);

      let params;
      if (!p?.fromStart && lastVisiblePubRef) {
        params = query(
          docRef,
          orderBy('date', 'desc'),
          startAfter(lastVisiblePubRef),
          where('themeId', '==', id),
          where('sourceLang', '==', userStore.user?.language || 'en'),
          where('date', '<', Timestamp.now()),
          limit(QUERY_LIMIT)
        );
      } else {
        params = query(
          docRef,
          orderBy('date', 'desc'),
          where('themeId', '==', id),
          where('sourceLang', '==', userStore.user?.language || 'en'),
          where('date', '<', Timestamp.now()),
          limit(QUERY_LIMIT)
        );
      }

      const querySnapshot = await getDocs(params);
      const publications: PublicationI[] = [];
      querySnapshot.forEach(async (doc) => {
        const publication = doc.data() as PublicationI;
        publications.push(publication);
      });

      const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
      console.log(querySnapshot.size);
      const isLastPage = querySnapshot.size < QUERY_LIMIT;
      lastVisiblePubRef = isLastPage ? null : lastVisible;

      return {
        data: publications.filter((p) => !p.reportingUserIds?.includes(userId)),
        isLastPage: isLastPage,
      };
    },
    async getBySubthemeId(id: string, p?: ApiFetchParamsI) {
      const docRef = collection(db, publicationsPath);
      const userId = (await getUserId()) as string;
      const userStore = useUserStore();

      let params;
      if (!p?.fromStart && lastVisiblePubRef) {
        params = query(
          docRef,
          orderBy('date', 'desc'),
          startAfter(lastVisiblePubRef),
          where('subthemeId', '==', id),
          where('sourceLang', '==', userStore.user?.language || 'en'),
          limit(QUERY_LIMIT)
        );
      } else {
        params = query(
          docRef,
          orderBy('date', 'desc'),
          where('subthemeId', '==', id),
          where('sourceLang', '==', userStore.user?.language || 'en'),
          limit(QUERY_LIMIT)
        );
      }

      const querySnapshot = await getDocs(params);
      const publications: PublicationI[] = [];
      querySnapshot.forEach(async (doc) => {
        const publication = doc.data() as PublicationI;
        publications.push(publication);
      });

      const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
      console.log(querySnapshot.size);
      const isLastPage = querySnapshot.size < QUERY_LIMIT;
      lastVisiblePubRef = isLastPage ? null : lastVisible;

      return {
        data: publications.filter((p) => !p.reportingUserIds?.includes(userId)),
        isLastPage: isLastPage,
      };
    },
    async getFavoriteThemes(p?: ApiFetchParamsI) {
      const docRef = collection(db, publicationsPath);
      // const userId = (await getUserId()) as string;

      const userStore = useUserStore();
      const userId = userStore.user.id;
      const favThemes = userStore.user.themes;

      console.log('fav Themes', favThemes);

      console.log('p', p);

      let queryParams: QueryConstraint[] = [
        orderBy('date', 'desc'),
        where('themeId', 'in', favThemes),
        where('sourceLang', '==', userStore.user?.language || 'en'),
      ];

      if (!p?.fromStart && lastVisiblePubRef) {
        queryParams = [...queryParams, startAfter(lastVisiblePubRef)];
      }

      queryParams = [...queryParams, limit(QUERY_LIMIT)];

      console.log('query params', queryParams);

      const querySnapshot = await getDocs(query(docRef, ...queryParams));
      const publications: PublicationI[] = [];
      querySnapshot.forEach(async (doc) => {
        const publication = doc.data() as PublicationI;
        publications.push(publication);
      });

      const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
      console.log(querySnapshot.size);
      const isLastPage = querySnapshot.size < QUERY_LIMIT;
      lastVisiblePubRef = isLastPage ? null : lastVisible;

      return {
        data: publications.filter((p) => !p.reportingUserIds?.includes(userId)),
        isLastPage: isLastPage,
      };
    },
    async reactPostById(id: string, userId: string) {
      const publicationRef = doc(db, publicationsPath, id);

      return updateDoc(publicationRef, {
        reactedUserIds: arrayUnion(userId),
        reactionsCount: increment(1),
      });
    },
    async unreactPostById(id: string, userId: string) {
      const publicationRef = doc(db, publicationsPath, id);

      return updateDoc(publicationRef, {
        reactedUserIds: arrayRemove(userId),
        reactionsCount: increment(-1),
      });
    },
    async favoritePostById(id: string, userId: string) {
      const publicationRef = doc(db, publicationsPath, id);

      return updateDoc(publicationRef, {
        favoritedUserIds: arrayUnion(userId),
        favoritesCount: increment(1),
      });
    },
    async unfavoritePostById(id: string, userId: string) {
      const publicationRef = doc(db, publicationsPath, id);

      return updateDoc(publicationRef, {
        favoritedUserIds: arrayRemove(userId),
        favoritesCount: increment(-1),
      });
    },
    async reportById(id: string, userId: string, reportReason: ReportReasonI) {
      const publicationReportCollection = collection(
        db,
        publicationReportsPath
      );
      const publicationReportRef = doc(publicationReportCollection);

      await setDoc(publicationReportRef, {
        message: reportReason.message,
        type: reportReason.type,
        reasonId: reportReason.id,
        publicationId: id,
        userId,
      });

      return publicationReportRef.id;
    },
    async blockById(id: string, userId: string) {
      const publicationRef = doc(db, publicationsPath, id);

      const params: Partial<Record<keyof PublicationI, any>> = {
        reportingUserIds: arrayUnion(userId),
        reportsCount: increment(1),
      };

      return updateDoc(publicationRef, params);
    },
    async unblockById(id: string, userId: string) {
      const publicationRef = doc(db, publicationsPath, id);

      const params: Partial<Record<keyof PublicationI, any>> = {
        reportingUserIds: arrayRemove(userId),
        reportsCount: increment(-1),
      };

      return updateDoc(publicationRef, params);
    },
    // async searchBy(params: {
    //   str: string;
    //   page: number;
    //   publicationParams?: Partial<PublicationI>;
    // }) {
    //   if (!searchClient) {
    //     return false;
    //   }

    //   const publicationsRef = collection(db, publicationsPath);
    //   const publications: PublicationI[] = [];
    //   const searchParams: SearchParams = {
    //     q: params.str,
    //     query_by: 'title, content.text',
    //     page: params.page,
    //     per_page: QUERY_LIMIT,
    //     sort_by: 'date:desc',
    //   };

    //   if (params.publicationParams?.themeId) {
    //     searchParams.filter_by = `themeId:=${params.publicationParams.themeId}`;
    //   }
    //   if (params.publicationParams?.subthemeId) {
    //     searchParams.filter_by = `subthemeId:=${params.publicationParams.subthemeId}`;
    //   }

    //   const searchRes = await searchClient
    //     .collections('publicationsprod')
    //     .documents()
    //     .search(searchParams, {});

    //   const pIds =
    //     searchRes.hits?.map((r) => {
    //       const doc = r.document as PublicationI;
    //       return doc.publicationId;
    //     }) || [];

    //   if (!pIds.length) {
    //     return {
    //       data: [],
    //       items: 0,
    //       limit: QUERY_LIMIT,
    //     };
    //   }

    //   const q = query(publicationsRef, where('publicationId', 'in', pIds));
    //   const querySnapshot = await getDocs(q);
    //   querySnapshot.forEach((p) => {
    //     const publication = p.data() as PublicationI;
    //     publications.push(publication);
    //   });

    //   console.log('searchRes =>', searchRes);

    //   return {
    //     data: publications,
    //     items: searchRes.found,
    //     limit: QUERY_LIMIT,
    //   };
    // },
    async searchAIByQuery(q: string, offset = 0) {
      const searchParams = {
        q,
        searchableAttributes: ['selftext'],
        showHighlights: true,
        limit: 10,
        offset,
      };
      const host = 'https://marqo.feelyou.com/indexes/feelyou-posts/search';

      const rawRes = await fetch(host, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(searchParams),
      });
      return await rawRes.json();
    },
    async uploadImageByUrl(url: string, id?: string) {
      let publicationId = id;

      if (!publicationId) {
        const publicationCollection = collection(db, publicationsPath);
        const publicationRef = doc(publicationCollection);

        publicationId = publicationRef.id;
      }

      const img = await fetch(url);
      const imgBlob = await img.blob();
      console.log('img blob', imgBlob);
      const filename = `${img.url.split('/')[3]}.${imgBlob.type.split('/')[1]}`;
      console.log('name', filename);
      const imageRef = ref(
        storage,
        `publications/${publicationId}/${filename}`
      );

      const uploadedFile = await uploadBytes(imageRef, imgBlob);
      console.log('uploadedFile', uploadedFile);

      return { id: publicationId, img: uploadedFile };
    },
    async getReportReasons() {
      const docRef = collection(db, reportReasonsPath);
      const params = query(docRef);

      const querySnapshot = await getDocs(params);
      const reasons: ReportReasonI[] = [];

      querySnapshot.forEach((doc) => {
        const reason = doc.data() as ReportReasonI;
        reasons.push(reason);
      });

      return reasons;
    },
    async setHiddenById(id: string, isHidden: boolean) {
      await updateDoc(doc(db, publicationsPath, id), { isHidden });
    },
  };
}
