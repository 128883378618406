<template>
  <ion-modal
    :class="['modal', { 'modal--mobile-center': props.isMobileCenter }]"
    :is-open="props.isOpened"
    :can-dismiss="!isNotCloseOutside"
    @did-dismiss="emit('close')"
  >
    <div class="inner-content">
      <ion-toolbar v-if="props.title || !props.isHideClose">
        <ion-title v-if="props.title">
          <span style="white-space: normal">{{ props.title }}</span>
        </ion-title>
        <ion-buttons v-if="!props.isHideClose" slot="end">
          <ion-button @click="emit('close')">
            <ion-icon :icon="closeOutline" />
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
      <div v-else style="height: 20px; width: 100%"></div>
      <div class="modal__container">
        <slot />
      </div>
    </div>
  </ion-modal>
</template>

<script setup lang="ts">
import { withDefaults } from 'vue';
import {
  IonIcon,
  IonModal,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonButton,
} from '@ionic/vue';
import { closeOutline } from 'ionicons/icons';

interface Props {
  isOpened: boolean;
  title?: string;
  isHideClose?: boolean;
  isNotCloseOutside?: boolean;
  isMobileCenter?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  isMobileCenter: true,
});
const emit = defineEmits(['close']);
</script>

<style lang="scss" scoped>
ion-modal {
  --width: 600px;
  --height: auto;
  --ion-backdrop-color: #172659;
  --border-radius: 16px;
  padding-left: 20px;
  padding-right: 20px;

  :deep(.ion-page) {
    position: relative;
    display: block;
    contain: content;

    .inner-content {
      max-height: 75vh;
      overflow: auto;
    }
  }
}

.modal {
  &__container {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
    padding: 1em 16px 16px;
  }

  ion-toolbar {
    --min-height: 50px;
  }

  ion-title {
    padding: 0 35px;
  }
}

@media (max-width: 720px) {
  ion-modal {
    &.modal--mobile-center {
      padding: 0 8px;
    }
  }

  ion-modal:not(.modal--mobile-center)::part(content) {
    position: fixed !important;
    top: auto !important;
    bottom: 0 !important;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}
</style>
