<template>
  <div :class="['hello-modal', { 'hello-modal--is-show': isShow }]">
    <div class="hello-modal__content">
      {{ t('hello') }}, {{ userStore.user.alias }}!
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue';
import { useUserStore } from '@/stores/user';
import { useInternalization } from '@/composables/useInternalization';

const userStore = useUserStore();
const { t } = useInternalization();

const isShow = ref(false);
let isShowed = false;

watch(
  () => userStore.isLogged,
  () => {
    if (userStore.isLogged) {
      showModal();
    } else {
      isShowed = false;
    }
  }
);

function showModal() {
  if (!isShowed && userStore.user.alias) {
    isShow.value = true;
    isShowed = true;
    setTimeout(() => {
      isShow.value = false;
    }, 2000);
  }
}

setTimeout(() => showModal());
</script>

<style lang="scss" scoped>
.hello-modal {
  position: fixed;
  top: calc(var(--ion-safe-area-top) + 4px);
  left: 0;
  width: calc(100% - 14px);
  transform: translateY(-200%);
  transition: 0.3s;
  margin: 0 8px;

  &__content {
    max-width: 560px;
    background-color: #fff;
    border-radius: 20px;
    box-shadow: 0 4px 10px 0 rgba(55, 34, 96, 0.1);
    font-size: 17px;
    font-weight: 600;
    line-height: 44px;
    color: #172659;
    box-sizing: border-box;
    padding: 11px 14px;
    margin: 0 auto;
  }

  &--is-show {
    transform: none;
  }
}
</style>
