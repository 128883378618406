<template>
  <base-modal
    :is-opened="props.isOpened"
    class="app-feedback-modal"
    @close="close"
  >
    <div class="app-feedback-modal__title">{{ modalTitle }}</div>
    <div v-if="type === 'initial'" class="app-feedback-modal__buttons-wrapper">
      <base-button primary class="modal__btn" @click="handleLike">
        <div>{{ t('yes') }}</div>
        <ion-icon :icon="thumbsUp" />
      </base-button>
      <base-button secondary class="modal__btn" @click="handleDislike">
        {{ t('no') }}
        <ion-icon :icon="thumbsDown" />
      </base-button>
    </div>
    <div class="app-feedback-modal__text">
      {{ modalText }}
    </div>
    <div
      v-if="type === 'initial'"
      class="app-feedback-modal__later-btn"
      @click="close"
    >
      {{ t('reviewLater') }}
    </div>
    <base-button v-else-if="type === 'negative'" @click="handleContact">
      {{ t('contactUs') }}
    </base-button>
    <base-button v-else-if="type === 'positive'" @click="handleReveiw">
      {{ t('leaveReview') }}
    </base-button>
  </base-modal>
</template>

<script setup lang="ts">
import BaseModal from '@/components/modals/BaseModal.vue';
import BaseButton from '@/components/BaseButton.vue';
import { IonIcon } from '@ionic/vue';
import { AppFeedbackType } from '@/types/ui';
import { computed } from 'vue';
import { thumbsDown, thumbsUp } from 'ionicons/icons';
import { useInternalization } from '@/composables/useInternalization';

const { t } = useInternalization();

const props = defineProps<{
  isOpened: boolean;
  type: AppFeedbackType;
}>();
const emit = defineEmits(['close', 'like', 'dislike', 'review', 'contact']);

const modalTitle = computed(() =>
  props.type === 'initial'
    ? t('modals.appFeedbackQuestionTitle')
    : t('modals.appFeedbackResponseTitle')
);

const modalText = computed(() => {
  if (props.type === 'initial') {
    return t('modals.appFeedbackQuestionText');
  } else if (props.type === 'negative') {
    return t('modals.appFeedbackNegativeResponseText');
  } else {
    return t('modals.appFeedbackPositiveResponseText');
  }
});

function close() {
  if (props.isOpened) {
    emit('close');
  }
}
function handleLike() {
  emit('like');
}
function handleDislike() {
  emit('dislike');
}
function handleReveiw() {
  emit('review');
}
function handleContact() {
  emit('contact');
}
</script>

<style lang="scss" scoped>
.app-feedback-modal {
  &__title {
    font-size: 18px;
    line-height: 24px;
    font-weight: 600;
    margin-bottom: 10px;
    color: #172659;
  }
  &__text {
    font-size: 15px;
    line-height: 20px;
    color: #172659;
    text-align: center;
    max-width: 400px;
    margin-bottom: 1em;
  }
  &__buttons-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    margin-bottom: 15px;

    & > *:first-child {
      margin-right: 0.5em;
    }
  }
  &__later-btn {
    font-size: 15px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;
    color: #6289ff;
    text-decoration: underline;
    cursor: pointer;
  }
}
.modal__btn {
  width: 100px;
  font-size: 0.8rem;
  display: flex;
  align-items: center;
  justify-content: center;

  ion-icon {
    margin-left: 6px;
    font-size: 0.8rem;
  }
}
</style>
