<template>
  <base-modal
    :is-opened="props.isOpened"
    is-hide-close
    :is-not-close-outside="!isClose"
    class="no-internet-modal">
    <div class="no-internet-modal__icon">
      <img src="/assets/icon/no-internet-icon.svg" alt="" />
    </div>
    <h2 class="no-internet-modal__title">No internet connection</h2>
    <base-button class="no-internet-modal__button" @click="reload">
      <ion-spinner v-if="isLoading" />
      <template v-else>
        <img src="/assets/icon/refresh-icon.svg" alt="" />
        Reload
      </template>
    </base-button>
  </base-modal>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { IonSpinner } from '@ionic/vue';
import { Network } from '@capacitor/network';
import { usePopup } from '@/composables/usePopup';

import BaseModal from '@/components/modals/BaseModal.vue';
import BaseButton from '@/components/BaseButton.vue';

const { toggleErrorPopup } = usePopup();

const props = defineProps<{
  isOpened: boolean;
}>();
const emit = defineEmits(['close']);

const isClose = ref(false);
const isLoading = ref(false);

async function reload() {
  if (isLoading.value) {
    return;
  }
  isLoading.value = true;
  const status = await Network.getStatus();
  isLoading.value = false;
  if (status.connected) {
    isClose.value = true;
    emit('close');
  } else {
    toggleErrorPopup('No internet connection');
  }
}
</script>

<style lang="scss" scoped>
.no-internet-modal {
  --width: 344px;

  &__icon {
    width: 32px;
    height: 32px;
  }

  &__title {
    max-width: 160px;
    font-size: 15px;
    font-weight: 600;
    line-height: 20px;
    text-align: center;
    margin: 16px auto 24px;
  }

  &__button {
    width: 177px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: 500;

    img {
      margin-right: 8px;
    }
  }
}
</style>