import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import {
  useIsLoggedPrefs,
  useLanguagePrefs,
  useOnboardingCompletionPrefs,
} from '@/stores/preferences';
import { useUserStore } from '@/stores/user';
import {
  getNormalizedLocale,
  setNativeControlsStyle,
} from '@/services/methods';
import { useAppOnlyFeature } from '@/composables/useAppOnlyFeature';

const FeedPage = () => import('@/views/FeedPage.vue');
const SignInPage = () => import('@/views/SignInPage.vue');
const SignUpPage = () => import('@/views/SignUpPage.vue');
const AuthPage = () => import('@/views/AuthPage.vue');
const OnboardingPage = () => import('@/views/OnboardingPage.vue');
const PostPage = () => import('@/views/PostPage.vue');
const ThemesPage = () => import('@/views/ThemesPage.vue');
const CreatePostPage = () => import('@/views/CreatePostPage.vue');
const EditPostPage = () => import('@/views/EditPostPage.vue');
const ProfilePage = () => import('@/views/ProfilePage.vue');
const EditProfilePage = () => import('@/views/EditProfilePage.vue');
const AboutMePage = () => import('@/views/AboutMePage.vue');
const ChangeNamePage = () => import('@/views/ChangeNamePage.vue');
const ChangePasswordPage = () => import('@/views/ChangePasswordPage.vue');
const ChangeEmailPage = () => import('@/views/ChangeEmailPage.vue');
const AboutPage = () => import('@/views/AboutPage.vue');
const LegalPage = () => import('@/views/LegalPage.vue');
const UserFaqPage = () => import('@/views/UserFaqPage.vue');
const ForgotPasswordPage = () => import('@/views/ForgotPasswordPage.vue');
const ChangeSettingsPage = () => import('@/views/ChangeSettingsPage.vue');
const StoryPage = () => import('@/views/StoryPage.vue');
const AiSearchPage = () => import('@/views/AiSearchPage.vue');
const AddStoryPage = () => import('@/views/AddStoriesPage.vue');
const StoriesPage = () => import('@/views/StoriesPage.vue');
const NotFoundPage = () => import('@/views/NotFoundPage.vue');
const NotificationsPage = () => import('@/views/NotificationsPage.vue');
const CreatedPostPage = () => import('@/views/CreatedPostPage.vue');
const ExpertAdvicePaymentPage = () =>
  import('@/views/ExpertAdvicePaymentPage.vue');
const TherapyPage = () => import('@/views/TherapyPage.vue');
const TherapyCoursesPage = () => import('@/views/TherapyCoursesPage.vue');
const TherapyVideoPage = () => import('@/views/TherapyVideoPage.vue');
const TherapyCoursePage = () => import('@/views/TherapyCoursePage.vue');
const TherapyThemePage = () => import('@/views/TherapyThemePage.vue');
const QuickCalmVideoPage = () => import('@/views/QuickCalmVideoPage.vue');
const QuickCalmListPage = () => import('@/views/QuickCalmListPage.vue');
const BreathingPracticeListPage = () =>
  import('@/views/BreathingPracticeListPage.vue');
const BreathingPracticeVideoPage = () =>
  import('@/views/BreathingPracticeVideoPage.vue');
const VisualizationListPage = () => import('@/views/VisualizationListPage.vue');
const VisualizationVideoPage = () =>
  import('@/views/VisualizationVideoPage.vue');
const SoundTherapyListPage = () => import('@/views/SoundTherapyListPage.vue');
const SoundTherapyVideoPage = () => import('@/views/SoundTherapyVideoPage.vue');
const AchievementsPage = () => import('@/views/AchievementsPage.vue');
const TherapyDailyCheckPage = () => import('@/views/TherapyDailyCheckPage.vue');
const SuccessPaymentPage = () => import('@/views/SuccessPaymentPage.vue');
const CanceledPaymentPage = () => import('@/views/CanceledPaymentPage.vue');
const HelpPage = () => import('@/views/HelpPage.vue');
const TestViewPage = () => import('@/views/TestViewPage.vue');
const TestListPage = () => import('@/views/TestListPage.vue');
const TestInProgressPage = () => import('@/views/TestInProgressPage.vue');
const TestResultsPage = () => import('@/views/TestResultsPage.vue');
const EditProfileChangeLanguagePage = () =>
  import('@/views/EditProfileChangeLanguagePage.vue');

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/feed',
  },
  {
    path: '/auth',
    name: 'Auth',
    component: AuthPage,
    meta: {
      hideTapbar: true,
    },
  },
  {
    path: '/auth/sign-up',
    name: 'SignUp',
    component: SignUpPage,
    meta: {
      hideTapbar: true,
    },
  },
  {
    path: '/auth/sign-in',
    name: 'SignIn',
    component: SignInPage,
    meta: {
      hideTapbar: true,
    },
  },
  {
    path: '/onboarding',
    name: 'Onboarding',
    component: OnboardingPage,
    meta: {
      hideTapbar: true,
    },
  },
  {
    path: '/feed',
    name: 'Feed',
    component: FeedPage,
  },
  {
    path: '/feed/search',
    name: 'AiSearch',
    component: AiSearchPage,
  },
  {
    path: '/feed/notifications',
    name: 'Notifications',
    component: NotificationsPage,
    meta: {
      private: true,
    },
  },
  {
    path: '/feed/discover',
    name: 'Discover',
    component: ThemesPage,
  },
  {
    path: '/feed/discover/:themeId',
    name: 'DiscoverTheme',
    component: FeedPage,
  },
  {
    path: '/posts/select-theme',
    name: 'PostSelectTheme',
    component: ThemesPage,
  },
  {
    path: '/posts/create',
    name: 'CreatePost',
    component: CreatePostPage,
    meta: {
      private: true,
      hideTapbar: true,
    },
  },
  {
    path: '/posts/created',
    name: 'CreatedPost',
    component: CreatedPostPage,
  },
  {
    path: '/publications/:id',
    redirect: (to) => {
      return { name: 'Post', params: { id: to.params.id } };
    },
  },
  {
    path: '/posts/:id',
    name: 'Post',
    component: PostPage,
    meta: {
      hideTapbar: true,
    },
  },
  {
    path: '/posts/:id/edit',
    name: 'EditPost',
    component: EditPostPage,
    meta: {
      private: true,
    },
  },
  {
    path: '/profile/:id',
    name: 'Profile',
    component: ProfilePage,
  },
  {
    path: '/my-profile',
    name: 'MyProfile',
    component: ProfilePage,
  },
  {
    path: '/my-profile/edit',
    name: 'EditProfile',
    component: EditProfilePage,
    meta: {
      private: true,
    },
  },
  {
    path: '/my-profile/edit/change-language',
    name: 'EditProfileChangeLanguage',
    component: EditProfileChangeLanguagePage,
    meta: {
      private: true,
    },
  },
  {
    path: '/my-profile/edit/about-me',
    name: 'AboutMe',
    component: AboutMePage,
  },
  {
    path: '/my-profile/edit/name',
    name: 'ChangeName',
    component: ChangeNamePage,
  },
  {
    path: '/my-profile/edit/password',
    name: 'ChangePassword',
    component: ChangePasswordPage,
  },
  {
    path: '/my-profile/edit/email',
    name: 'ChangeEmail',
    component: ChangeEmailPage,
  },
  {
    path: '/my-profile/about',
    name: 'About',
    component: AboutPage,
  },
  {
    path: '/my-profile/about/legal',
    name: 'Legal',
    component: LegalPage,
  },
  {
    path: '/my-profile/about/user-faq',
    name: 'UserFaq',
    component: UserFaqPage,
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: ForgotPasswordPage,
    meta: {
      hideTapbar: true,
    },
  },
  {
    path: '/change-settings',
    name: 'ChangeSettings',
    component: ChangeSettingsPage,
    meta: {
      hideTapbar: true,
    },
  },
  {
    path: '/stories',
    name: 'Stories',
    component: StoriesPage,
  },
  {
    path: '/stories/:id',
    name: 'Story',
    component: StoryPage,
    meta: {
      hideTapbar: true,
      nativeControlsStyle: 'dark',
    },
  },
  {
    path: '/stories/add',
    name: 'AddStory',
    component: AddStoryPage,
  },
  {
    path: '/expert-advice-payment',
    name: 'ExpertAdvicePayment',
    component: ExpertAdvicePaymentPage,
    meta: {
      private: true,
    },
  },
  {
    path: '/payment-success',
    name: 'PaymentSuccess',
    component: SuccessPaymentPage,
    meta: {
      private: true,
    },
  },
  {
    path: '/payment-cancel',
    name: 'PaymentCancel',
    component: CanceledPaymentPage,
    meta: {
      private: true,
    },
  },
  {
    path: '/therapy',
    name: 'Therapy',
    component: TherapyPage,
  },
  {
    path: '/therapy/courses',
    name: 'TherapyCourses',
    component: TherapyCoursesPage,
  },
  {
    path: '/therapy/categories/:themeId',
    name: 'TherapyThemes',
    component: TherapyThemePage,
  },
  {
    path: '/therapy/courses/:id',
    name: 'TherapyCourse',
    component: TherapyCoursePage,
  },
  {
    path: '/therapy/courses/:courseId/:videoId',
    name: 'TherapyCourseVideo',
    component: TherapyVideoPage,
    meta: {
      private: true,
      hideTapbar: true,
      nativeControlsStyle: 'dark',
    },
  },
  {
    path: '/therapy/daily-check',
    name: 'TherapyDailyCheck',
    component: TherapyDailyCheckPage,
    meta: {
      private: true,
    },
  },
  {
    path: '/therapy/visualization/:id',
    name: 'TherapyVisualizationVideo',
    component: VisualizationVideoPage,
    meta: {
      hideTapbar: true,
      nativeControlsStyle: 'dark',
    },
  },
  {
    path: '/therapy/quick-calming/:id',
    name: 'TherapyQuickCalmVideo',
    component: QuickCalmVideoPage,
    meta: {
      hideTapbar: true,
      nativeControlsStyle: 'dark',
    },
  },
  {
    path: '/therapy/breathing-practice/:id',
    name: 'TherapyBreathingPracticeVideo',
    component: BreathingPracticeVideoPage,
    meta: {
      hideTapbar: true,
      nativeControlsStyle: 'dark',
    },
  },
  {
    path: '/therapy/sound/:id',
    name: 'TherapySoundVideo',
    component: SoundTherapyVideoPage,
    meta: {
      hideTapbar: true,
      nativeControlsStyle: 'dark',
    },
  },
  {
    path: '/my-profile/achievements',
    name: 'Achievements',
    component: AchievementsPage,
  },
  {
    path: '/help',
    name: 'Help',
    component: HelpPage,
  },
  {
    path: '/therapy-test-view/:id',
    name: 'TestView',
    component: TestViewPage,
  },
  {
    path: '/therapy/test-list',
    name: 'TestList',
    component: TestListPage,
  },
  {
    path: '/therapy-test-results/:userTestId/:testId',
    name: 'TestResults',
    component: TestResultsPage,
    meta: {
      hideTapbar: true,
      private: true,
    },
  },
  {
    path: '/therapy/test-in-progress/:id',
    name: 'TestInProgress',
    component: TestInProgressPage,
    meta: {
      hideTapbar: true,
      private: true,
    },
  },
  {
    path: '/therapy/quick-calm-list',
    name: 'QuickCalmListPage',
    component: QuickCalmListPage,
  },
  {
    path: '/therapy/breathing-practice-list',
    name: 'BreathingPracticeListPage',
    component: BreathingPracticeListPage,
  },
  {
    path: '/therapy/sound-therapy-list',
    name: 'SoundTherapyListPage',
    component: SoundTherapyListPage,
  },
  {
    path: '/therapy/visualiztion-list',
    name: 'VisualiztionListPage',
    component: VisualizationListPage,
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: NotFoundPage,
  },
];

const router = createRouter({
  history: createWebHistory('/'),
  routes,
});

const { handleAppOnlyRouteNavigation } = useAppOnlyFeature();

router.beforeEach(async (to, from, next) => {
  const { get: getIsLogged } = useIsLoggedPrefs();

  setNativeControlsStyle(
    to.meta.nativeControlsStyle as 'dark' | 'light' | null
  );

  if (to.name === 'Feed') {
    const { get } = useOnboardingCompletionPrefs();
    const isOnboardingComplete = await get();
    if (!isOnboardingComplete) {
      return next({ name: 'Onboarding' });
    }
  }

  if (to.name === 'Auth' || to.name === 'SignIn' || to.name === 'SignUp') {
    const isLogged = await getIsLogged();
    if (isLogged) {
      return next({ name: 'Feed', replace: true });
    }
  }

  if (to.meta.private) {
    const isLogged = await getIsLogged();
    if (!isLogged) {
      const userStore = useUserStore();
      const comingFromUrl = to;
      userStore.setRouteTargetForSuccessAuth(comingFromUrl);

      return next({ name: 'Auth' });
    }
  }
  if (to.meta.appOnly) {
    return handleAppOnlyRouteNavigation();
  }

  const { get: getLang } = useLanguagePrefs();

  if (to.query.lang && from.query.lang && to.query.lang === from.query.lang) {
    return next();
  }

  if (to.query.lang) {
    const l = to.query.lang as string;
    const normalizedLang = getNormalizedLocale(l);

    if (l === normalizedLang) {
      return next();
    } else {
      return next({
        ...to,
        query: { ...to.query, lang: normalizedLang },
      });
    }
  } else {
    if (from.query.lang) {
      return next({
        ...to,
        query: { ...to.query, lang: from.query.lang },
      });
    } else {
      const pLang = await getLang();
      const normalizedLang = getNormalizedLocale(pLang || 'en');
      return next({
        ...to,
        query: { ...to.query, lang: normalizedLang },
      });
    }
  }
});

export default router;
