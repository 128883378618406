import { initializeApp } from 'firebase/app';

// import { FirebaseAppCheck } from '@capacitor-firebase/app-check';
import {
  FirebaseAuthentication,
  User,
} from '@capacitor-firebase/authentication';
import { useUserStore } from '@/stores/user';

const isProdConfig = process.env.VUE_APP_CONFIG === 'prod';

const FIREBASE_PROD_CONFIG = {
  apiKey: 'AIzaSyAuuPq656b89sJtITa53v8DahfoSqXRHjA',
  authDomain: 'mental-fdc53.firebaseapp.com',
  databaseURL:
    'https://mental-fdc53-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'mental-fdc53',
  storageBucket: 'mental-fdc53.appspot.com',
  messagingSenderId: '1017992241300',
  appId: '1:1017992241300:web:8a608142302e5a9b1b9481',
  measurementId: 'G-QP8NJL1LJJ',
};
const FIREBASE_QA_CONFIG = {
  apiKey: 'AIzaSyB42L4aKmQovRkNTOaQFsb_sdCw0yMUUZg',
  authDomain: 'mental-qaa.firebaseapp.com',
  projectId: 'mental-qaa',
  storageBucket: 'mental-qaa.appspot.com',
  messagingSenderId: '806347282974',
  appId: '1:806347282974:web:21846eb4267f2350a0ac0f',
  measurementId: 'G-R5CYQWWKHR',
};

initializeApp(isProdConfig ? FIREBASE_PROD_CONFIG : FIREBASE_QA_CONFIG);

// if (process.env.NODE_ENV === 'production' && isProdConfig) {
//   FirebaseAppCheck.initialize({
//     siteKey: '6LckG5klAAAAAGFAB6JcBcd8WSMRDR36JbkXxZku',
//     isTokenAutoRefreshEnabled: true,
//   })
//     .then((val: any) => {
//       console.log('val////////////////////////////', val);
//     })
//     .catch((e: Error) => {
//       console.log('eerrror app check////////////////////', e);
//     });
// }

export function createAuth() {
  return {
    createUserWithEmailAndPassword: (email: string, password: string) => {
      return FirebaseAuthentication.createUserWithEmailAndPassword({
        email,
        password,
      });
    },
    signInWithEmailAndPassword: (email: string, password: string) => {
      return FirebaseAuthentication.signInWithEmailAndPassword({
        email,
        password,
      });
    },
    logout: () => {
      return FirebaseAuthentication.signOut();
    },
    watchAuthState: (handler: (u: User | null) => void) => {
      FirebaseAuthentication.addListener('authStateChange', (change) => {
        if (change.user) {
          const loggedUser: User = change.user;
          console.log('LOGGED USER => ', loggedUser);

          handler(loggedUser);
        } else {
          handler(null);
        }
      });
    },
    stopWatchAuthState: () => {
      return FirebaseAuthentication.removeAllListeners();
    },
    updatePassword: async (oldPassword: string, newPassword: string) => {
      const res = await FirebaseAuthentication.signInWithEmailAndPassword({
        email: useUserStore().user.email,
        password: oldPassword,
      });
      if (!res) {
        return res;
      }
      await FirebaseAuthentication.updatePassword({ newPassword });
      return true;
    },
    updateEmail: async (password: string, newEmail: string) => {
      const res = await FirebaseAuthentication.signInWithEmailAndPassword({
        email: useUserStore().user.email,
        password,
      });
      if (!res) {
        return res;
      }
      await FirebaseAuthentication.updateEmail({ newEmail });
      return true;
    },
    sendPasswordResetEmail: async (email: string) => {
      await FirebaseAuthentication.sendPasswordResetEmail({ email });
    },
    confirmPasswordReset: async (oobCode: string, newPassword: string) => {
      await FirebaseAuthentication.confirmPasswordReset({
        oobCode,
        newPassword,
      });
    },
    deleteMyAccount: async () => {
      await FirebaseAuthentication.deleteUser();
    },
    // getToken: async () => {
    //   const { token } = await FirebaseAppCheck.getToken({
    //     forceRefresh: false,
    //   });
    //   return token;
    // },
    applyActionCode: async (oobCode: string) => {
      await FirebaseAuthentication.applyActionCode({ oobCode });
    },
    signInWithGoogle: async () => {
      const res = await FirebaseAuthentication.signInWithGoogle();
      console.log('signin with google res', res);
      return res;
    },
    signInWithApple: async () => {
      const res = await FirebaseAuthentication.signInWithApple();
      console.log(res);
      return res;
    },
  };
}
