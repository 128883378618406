import api from '@/api';
import { FirebaseMessaging, Notification } from '@capacitor-firebase/messaging';
import { PermissionState } from '@capacitor/core';
import { useUserStore } from '@/stores/user';
import { useRequestNotificationsShowedPrefs } from '@/stores/preferences';

interface IConstructorParams {
  isLogged: boolean;
  isNative: boolean;
  notificationClickHandler: (targetUrl: string) => void;
}

type NotificationData = {
  targetUrl: string;
};

export class NotificationService {
  private isNative = true;
  private token: string | null = null;
  private permissionStatus: PermissionState = 'prompt';
  private isLogged = false;
  public notificationClickHandler: IConstructorParams['notificationClickHandler'] =
    () => ({});

  constructor(params: IConstructorParams) {
    this.notificationClickHandler = params.notificationClickHandler;
    this.isLogged = params.isLogged;
    this.isNative = params.isNative;

    if (this.isLogged) {
      this.registerNotifications();
    }
  }

  async registerNotifications(isAccepted = false) {
    if (!this.isNative) {
      return;
    }

    this.token = null;
    this.removeAllListeners();

    const permsState = await this.checkPermissions();
    console.log('permsState', permsState);

    if (permsState === 'granted') {
      await this.getToken();
    } else {
      if (permsState === 'prompt' || permsState === 'prompt-with-rationale') {
        if (!isAccepted) {
          const { get } = useRequestNotificationsShowedPrefs();
          const isRequestNotificationsShowed = await get();
          if (!isRequestNotificationsShowed) {
            const userStore = useUserStore();
            userStore.setIsShowRequestNotifications(true);
            return;
          }
        }

        const requestedPermState = await this.requestPermissions();

        if (requestedPermState !== 'granted') {
          return;
        }

        await this.getToken();
      }
    }

    if (this.token) {
      await api.users.setFcmToken(this.token);

      this.addNotificationActionPerformedListener();
    }
  }
  checkPermissions = async () => {
    const result = await FirebaseMessaging.checkPermissions();
    this.permissionStatus = result.receive;
    return result.receive;
  };
  requestPermissions = async () => {
    const result = await FirebaseMessaging.requestPermissions();
    this.permissionStatus = result.receive;
    return result.receive;
  };
  getToken = async () => {
    const result = await FirebaseMessaging.getToken();
    const token = result.token;
    this.token = token;

    console.log('NOTIFICATION TOKEN =========+>', this.token);

    return token;
  };
  deleteToken = async () => {
    await FirebaseMessaging.deleteToken();
  };
  getDeliveredNotifications = async () => {
    const result = await FirebaseMessaging.getDeliveredNotifications();
    return result.notifications;
  };
  removeDeliveredNotifications = async (notifications: Notification[]) => {
    await FirebaseMessaging.removeDeliveredNotifications({
      notifications,
    });
  };
  removeAllDeliveredNotifications = async () => {
    await FirebaseMessaging.removeAllDeliveredNotifications();
  };
  subscribeToTopic = async (topic: string) => {
    await FirebaseMessaging.subscribeToTopic({ topic });
  };
  unsubscribeFromTopic = async (topic: string) => {
    await FirebaseMessaging.unsubscribeFromTopic({ topic });
  };
  addTokenReceivedListener = async () => {
    await FirebaseMessaging.addListener('tokenReceived', (event) => {
      console.log('tokenReceived', { event });
    });
  };
  addNotificationReceivedListener = async () => {
    await FirebaseMessaging.addListener('notificationReceived', (event) => {
      console.log('notificationReceived', { event });
    });
  };
  addNotificationActionPerformedListener = async () => {
    await FirebaseMessaging.addListener(
      'notificationActionPerformed',
      (event) => {
        const notificationData = event.notification?.data as NotificationData;
        const targetUrl = notificationData?.targetUrl;

        if (targetUrl) {
          this.notificationClickHandler(targetUrl);
        }
      }
    );
  };
  removeAllListeners = async () => {
    await FirebaseMessaging.removeAllListeners();
  };
  private setIsLogged(isLogged: boolean) {
    this.isLogged = isLogged;
  }
}
