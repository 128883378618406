import {
  doc,
  getFirestore,
  getDoc,
  getDocs,
  query,
  where,
  collection,
} from 'firebase/firestore';
import { ThemeI } from '@/types';

export function createThemes() {
  const db = getFirestore();
  const docPath = 'themes';

  return {
    async getAll() {
      const themes: ThemeI[] = [];
      const themeRef = collection(db, docPath);
      // const themeQuery = query(themeRef, where('isHidden', '!=', true));
      const querySnapshot = await getDocs(themeRef);
      querySnapshot.forEach((doc) => {
        const theme = doc.data() as ThemeI;
        themes.push(theme);
      });

      return themes;
    },
    async getById(id: string) {
      const res = await getDoc(doc(db, docPath, id));
      return res.data() as ThemeI;
    },
    async getByName(name: string): Promise<ThemeI | null> {
      let res: ThemeI | null = null;
      const themeRef = collection(db, docPath);
      const themeQuery = query(themeRef, where('name', '==', name));
      const querySnapshot = await getDocs(themeQuery);
      querySnapshot.forEach((theme) => {
        res = theme.data() as ThemeI;
      });

      return res;
    },
  };
}
