<template>
  <div
    :class="['feed-action-buttons', { 'feed-action-buttons--hide': isHide }]"
    slot="fixed"
    vertical="bottom"
  >
    <div class="feed-action-buttons__container">
      <base-button
        :class="[
          'feed-action-buttons__btn',
          { 'feed-action-buttons__btn--active': isActiveLink('feed') },
        ]"
        primary
        @click="handleFeedClick"
      >
        <div class="feed-action-buttons__btn-inner">
          <ion-icon icon="/assets/icon/feed.svg" />
          <div class="feed-action-buttons__btn-text">{{ t('feed') }}</div>
        </div>
      </base-button>
      <base-button
        :class="[
          'feed-action-buttons__btn',
          { 'feed-action-buttons__btn--active': isActiveLink('therapy') },
        ]"
        primary
        @click="handleTherapyClick"
      >
        <div class="feed-action-buttons__btn-inner">
          <ion-icon icon="/assets/icon/therapy.svg" />
          <div class="feed-action-buttons__btn-text">{{ t('therapy') }}</div>
        </div>
      </base-button>
      <base-button
        :class="[
          'feed-action-buttons__btn',
          'feed-action-buttons__btn--help',
          { 'feed-action-buttons__btn--active': isActiveLink('help') },
        ]"
        primary
        @click="handleHelpClick"
      >
        <div class="feed-action-buttons__btn-inner">
          <ion-icon icon="/assets/icon/help.svg" size="48" />
          <div class="feed-action-buttons__btn-text">{{ t('help') }}</div>
        </div>
      </base-button>
      <base-button
        :class="[
          'feed-action-buttons__btn',
          { 'feed-action-buttons__btn--active': isActiveLink('stories') },
        ]"
        id="speak-out-btn"
        primary
        @click="handleStoriesClick"
      >
        <div class="feed-action-buttons__btn-inner">
          <ion-icon icon="/assets/icon/stories.svg" />
          <div class="feed-action-buttons__btn-text">{{ t('stories') }}</div>
        </div>
      </base-button>
      <base-button
        :class="[
          'feed-action-buttons__btn',
          { 'feed-action-buttons__btn--active': isActiveLink('my-profile') },
        ]"
        primary
        @click="handleProfileClick"
      >
        <div class="feed-action-buttons__btn-inner">
          <ion-icon icon="/assets/icon/profile.svg" />
          <div class="feed-action-buttons__btn-text">{{ t('profile') }}</div>
        </div>
      </base-button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, watch, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { IonIcon } from '@ionic/vue';
import { Capacitor } from '@capacitor/core';
import { Keyboard } from '@capacitor/keyboard';
import { logEvent } from '@/services/analytics';

import BaseButton from '@/components/BaseButton.vue';
import { useInternalization } from '@/composables/useInternalization';

const router = useRouter();
const route = useRoute();
const { t } = useInternalization();

const isHide = ref(route.meta.hideTapbar);

watch(
  () => route.meta.hideTapbar,
  () => (isHide.value = route.meta.hideTapbar)
);

onMounted(() => {
  if (Capacitor.isNativePlatform()) {
    Keyboard.addListener('keyboardWillShow', () => {
      if (!route.meta.hideTapbar) {
        isHide.value = true;
      }
    });
    Keyboard.addListener('keyboardDidHide', () => {
      if (!route.meta.hideTapbar) {
        isHide.value = false;
      }
    });
  }
});

function isActiveLink(routePath: string) {
  return route.path.startsWith(`/${routePath}`);
}

function scrollToTop() {
  const activeIonContentEl = document
    .querySelector('.ion-page .ion-page:not(.ion-page-hidden)')
    ?.querySelector('ion-content');
  if (activeIonContentEl) {
    activeIonContentEl.shadowRoot
      ?.querySelector('.inner-scroll')
      ?.scrollTo({ top: 0, behavior: 'smooth' });
  }
}

function handleFeedClick() {
  if (isActiveLink('feed')) {
    scrollToTop();
    return;
  }
  router.push({ name: 'Feed' });
}

function handleTherapyClick() {
  if (isActiveLink('therapy')) {
    scrollToTop();
    return;
  }

  router.push({ name: 'Therapy' });
}

function handleHelpClick() {
  if (isActiveLink('help')) {
    scrollToTop();
    return;
  }

  router.push({ name: 'Help' });
}

function handleStoriesClick() {
  if (isActiveLink('stories')) {
    scrollToTop();
    return;
  }

  router.push({ name: 'Stories' });
}

function handleProfileClick() {
  logEvent('topbar_click_profile');

  if (isActiveLink('my-profile')) {
    scrollToTop();
    return;
  }

  router.push({ name: 'MyProfile' });
}
</script>

<style lang="scss" scoped>
.feed-action-buttons {
  position: fixed;
  bottom: 20px;
  left: 50%;
  max-width: 700px;
  width: fit-content;
  display: flex;
  justify-items: center;
  align-items: center;
  transform: translate(-50%);
  transition: all 0.3s;
  z-index: 1;

  &--hide {
    bottom: -100px;
  }

  &__container {
    position: relative;
    max-width: 400px;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 10px;
    border-radius: 30px;
    margin: auto;
    padding: 0 1em;
    min-width: 350px;

    @media screen and (max-width: 768px) {
      gap: 0px;
    }

    &:after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      z-index: -1;
      border-radius: 30px;
      background: rgba(51, 102, 255, 0.7);
      box-shadow: 0 4px 10px rgba(55, 34, 96, 0.1);
      backdrop-filter: blur(3px);
    }
  }

  &__btn {
    --padding-top: 20px !important;
    --padding-bottom: 20px !important;
    --padding-start: 0.4em !important;
    --padding-end: 0.4em !important;
    --background: transparent !important;

    height: 60px;

    &--active {
      position: relative;

      &::after {
        content: '';
        display: block;
        position: absolute;
        bottom: 4px;
        left: 50%;
        transform: translate(-50%, -50%);
        height: 2px;
        width: 40px;
        background-color: #fff;
      }

      .feed-action-buttons__btn-inner {
        font-weight: 600;
      }
    }

    &-inner {
      display: flex;
      font-size: 12px;
      font-weight: 400;
      align-items: center;
      justify-content: space-between;
      flex-direction: column;

      & > *:first-child {
        font-size: 26px;
        height: 26px;
        margin-bottom: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    &--help {
      --overflow: visible;
      text-transform: uppercase;

      .feed-action-buttons {
        &__btn-inner > *:first-child {
          transform: scale(1.8);
          transform-origin: center bottom;
        }

        &__btn-text {
          font-weight: 600;
        }
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .feed-action-buttons {
    &__container {
      padding: 0 1em !important;
      min-width: 325px !important;
    }
    &__btn {
      &-inner {
        font-size: 11px !important;
      }
    }
  }
}
</style>
