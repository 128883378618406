import dayjs from 'dayjs';
import { Timestamp } from 'firebase/firestore';
import updateLocale from 'dayjs/plugin/updateLocale';
import 'dayjs/locale/ru';

dayjs.extend(updateLocale);
dayjs.updateLocale('en', {
  weekStart: 1,
});
// dayjs.locale('ru');

export const getDayjsInstance = () => dayjs;
export const getDatesBetween = (startDate: Date, endDate: Date) => {
  const dates: Date[] = [];
  const currentDate = new Date(startDate);

  while (currentDate <= endDate) {
    dates.push(new Date(currentDate));
    currentDate.setDate(currentDate.getDate() + 1);
  }
  return dates;
};
export const getFirebaseTimestampFromDate = (d: Date) => Timestamp.fromDate(d);
