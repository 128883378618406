import {
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  query,
  where,
} from 'firebase/firestore';
import { PaymentMetadataI, PurchaseProductI } from '@/types';

export function createPurchaseProducts() {
  const db = getFirestore();
  const docPath = 'purchase-products';

  const backendUrl =
    process.env.VUE_APP_PURCHASE_API_URL ||
    'http://localhost:3000/api/purchase-products';

  return {
    async getById(id: string) {
      const res = await getDoc(doc(db, docPath, id));
      return res.data() as PurchaseProductI;
    },
    async getProductByNameAndService(
      name: PurchaseProductI['name'],
      serviceName: PurchaseProductI['service']
    ): Promise<PurchaseProductI | null> {
      let product: PurchaseProductI | null = null;
      const ref = collection(db, docPath);
      const qParams: Partial<PurchaseProductI> = { service: serviceName, name };

      const q = query(
        ref,
        where('service', '==', qParams.service),
        where('name', '==', qParams.name)
      );
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        const i = doc.data() as PurchaseProductI;
        product = i;
      });

      return product;
    },
    async getProductsByService(serviceName: PurchaseProductI['service']) {
      const products: PurchaseProductI[] = [];
      const ref = collection(db, docPath);

      const qParams: Partial<PurchaseProductI> = { service: serviceName };

      const q = query(ref, where('service', '==', qParams.service));
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        const i = doc.data() as PurchaseProductI;
        products.push(i);
      });

      return products;
    },

    async processPayment(metadata: PaymentMetadataI) {
      try {
        const res = await fetch(
          `${backendUrl}/${metadata.product.service}/process-payment`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              accept: 'application/json',
            },
            body: JSON.stringify({ ...metadata }),
          }
        );

        const data = await res.json();
        console.log('data', data);

        return data;
      } catch (error) {
        console.log('error', error);
        throw new Error('Error processing payment');
      }
    },
    async processStripePayment(metadata: PaymentMetadataI) {
      try {
        const data = await this.processPayment(metadata);

        if (!data) {
          throw new Error('Error processing payment');
        }
        console.log('data', data);

        return data.url as string;
      } catch (error) {
        console.log('error', error);
        throw new Error('Error processing payment');
      }
    },
  };
}
