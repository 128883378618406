import {
  DailyCheckMoodItemI,
  LocaleI,
  PaymentPlatform,
  PurchaseProductI,
  SourceLang,
  TestItemI,
} from '@/types';
import { ComposerTranslation } from 'vue-i18n';

export const maxPublicationLength = 30_000;
export const HARD_THEME_NAMES = [
  'Bipolar disorder',
  'Self Harm',
  'Suicidal thoughts',
  'Drug addiction',
  'Alcohol addiction',
];

export function getMoodItems(t: ComposerTranslation): DailyCheckMoodItemI[] {
  return [
    {
      iconPath: '/assets/icon/mood-0.svg',
      title: t('moodItems.terrible'),
      id: '0',
      color: '#FBC8C8',
    },
    {
      iconPath: '/assets/icon/mood-1.svg',
      title: t('moodItems.bad'),
      id: '1',
      color: '#DFD4AB',
    },
    {
      iconPath: '/assets/icon/mood-2.svg',
      title: t('moodItems.okay'),
      id: '2',
      color: '#FEE9B3',
    },
    {
      iconPath: '/assets/icon/mood-3.svg',
      title: t('moodItems.good'),
      id: '3',
      color: '#CEE2D7',
    },
    {
      iconPath: '/assets/icon/mood-4.svg',
      title: t('moodItems.excellent'),
      id: '4',
      color: '#76AB8E',
    },
  ];
}
export function getEmotionItems(t: ComposerTranslation): DailyCheckMoodItemI[] {
  return [
    {
      iconPath: '/assets/icon/emotions-0.png',
      title: t('emotionItems.tired'),
      id: '0',
    },
    {
      iconPath: '/assets/icon/emotions-1.png',
      title: t('emotionItems.content'),
      id: '1',
    },
    {
      iconPath: '/assets/icon/emotions-2.png',
      title: t('emotionItems.confused'),
      id: '2',
    },
    {
      iconPath: '/assets/icon/emotions-3.png',
      title: t('emotionItems.grateful'),
      id: '3',
    },
    {
      iconPath: '/assets/icon/emotions-4.png',
      title: t('emotionItems.motivated'),
      id: '4',
    },
    {
      iconPath: '/assets/icon/emotions-5.png',
      title: t('emotionItems.anxious'),
      id: '5',
    },
    {
      iconPath: '/assets/icon/emotions-6.png',
      title: t('emotionItems.relaxed'),
      id: '6',
    },
    {
      iconPath: '/assets/icon/emotions-7.png',
      title: t('emotionItems.stressed'),
      id: '7',
    },
    {
      iconPath: '/assets/icon/emotions-8.png',
      title: t('emotionItems.sad'),
      id: '8',
    },
    {
      iconPath: '/assets/icon/emotions-9.png',
      title: t('emotionItems.proud'),
      id: '9',
    },
    {
      iconPath: '/assets/icon/emotions-10.png',
      title: t('emotionItems.unmotivated'),
      id: '10',
    },
    {
      iconPath: '/assets/icon/emotions-11.png',
      title: t('emotionItems.annoyed'),
      id: '11',
    },
    {
      iconPath: '/assets/icon/emotions-12.png',
      title: t('emotionItems.loving'),
      id: '12',
    },
    {
      iconPath: '/assets/icon/emotions-13.png',
      title: t('emotionItems.happy'),
      id: '13',
    },
    {
      iconPath: '/assets/icon/emotions-14.png',
      title: t('emotionItems.worried'),
      id: '14',
    },
    {
      iconPath: '/assets/icon/emotions-15.png',
      title: t('emotionItems.excited'),
      id: '15',
    },
    {
      iconPath: '/assets/icon/emotions-16.png',
      title: t('emotionItems.angry'),
      id: '16',
    },
    {
      iconPath: '/assets/icon/emotions-17.png',
      title: t('emotionItems.hopeless'),
      id: '17',
    },
    {
      iconPath: '/assets/icon/emotions-18.png',
      title: t('emotionItems.panicking'),
      id: '18',
    },
    {
      iconPath: '/assets/icon/emotions-19.png',
      title: t('emotionItems.exhausted'),
      id: '19',
    },
  ];
}
export function getSleepItems(t: ComposerTranslation): DailyCheckMoodItemI[] {
  return [
    {
      iconPath: '/assets/icon/sleep-0.svg',
      title: t('sleepItems.poor'),
      id: '0',
    },
    {
      iconPath: '/assets/icon/sleep-1.svg',
      title: t('sleepItems.okay'),
      id: '1',
    },
    {
      iconPath: '/assets/icon/sleep-2.svg',
      title: t('sleepItems.good'),
      id: '2',
    },
    {
      iconPath: '/assets/icon/sleep-3.svg',
      title: t('sleepItems.awesome'),
      id: '3',
    },
  ];
}
export function getSymptomItems(t: ComposerTranslation): DailyCheckMoodItemI[] {
  return [
    {
      title: t('symptomItems.panicAttack'),
      id: '0',
    },
    {
      title: t('symptomItems.constantSadness'),
      id: '1',
    },
    {
      title: t('symptomItems.moodSwings'),
      id: '2',
    },
    {
      title: t('symptomItems.selfHarmingThoughts'),
      id: '3',
    },
    {
      title: t('symptomItems.isolation'),
      id: '4',
    },
    {
      title: t('symptomItems.thinkingCircles'),
      id: '5',
    },
    {
      title: t('symptomItems.excessiveEmotions'),
      id: '6',
    },
    {
      title: t('symptomItems.lowAppetite'),
      id: '7',
    },
    {
      title: t('symptomItems.procrastination'),
      id: '8',
    },
    {
      title: t('symptomItems.anxiousThoughts'),
      id: '9',
    },
    {
      title: t('symptomItems.fear'),
      id: '10',
    },
    {
      title: t('symptomItems.healthConcerns'),
      id: '11',
    },
  ];
}
export enum PURCHASE_PRODUCT_NAMES {
  specialistAnswer = 'specialistAnswer',
}

export const PAYMENT_METHODS_MAP: Record<
  PaymentPlatform,
  PurchaseProductI['service']
> = {
  ANDROID: 'android-playstore',
  IOS: 'ios-appstore',
  WEB: 'stripe',
};

export const locales: {
  [k in SourceLang]: LocaleI;
} = {
  en: {
    value: 'en',
    title: 'English',
  },
  ru: {
    value: 'ru',
    title: 'Русский',
  },
  es: {
    value: 'es',
    title: 'Español',
    disabled: true,
  },
};

export const TEST_ITEM_TYPES: { [K in Required<TestItemI>['type']]: K } = {
  summary: 'summary',
  summary_with_categories: 'summary_with_categories',
  max_category_points: 'max_category_points',
  category_results_by_range: 'category_results_by_range',
} as const;

export const APP_LINKS = {
  'app-store': 'https://apps.apple.com/app/feelyou/id1673720647',
  'google-play': 'https://play.google.com/store/apps/details?id=io.feelyou.app',
  web: 'https://app.feelyou.com/',
};
