import {
  getFirestore,
  collection,
  getDocs,
  query,
  orderBy,
  where,
} from 'firebase/firestore';

import type { HelpItemI } from '@/types';
import { useUserStore } from '@/stores/user';

export function createHelpItems() {
  const db = getFirestore();
  const docPath = 'help-items';

  return {
    async getAll(parentId?: string) {
      const userStore = useUserStore();
      const helpItems: HelpItemI[] = [];
      const helpItemRef = collection(db, docPath);
      const params = [
        where('sourceLang', '==', userStore.user.language || 'en'),
        orderBy('key', 'asc'),
      ];
      if (typeof parentId === 'string') {
        params.push(where('parentId', '==', parentId));
      }
      const querySnapshot = await getDocs(query(helpItemRef, ...params));
      querySnapshot.forEach((doc) => {
        const helpItem = doc.data() as HelpItemI;
        helpItems.push(helpItem);
      });

      return helpItems;
    },
  };
}
