import {
  getFirestore,
  doc,
  getDoc,
  collection,
  setDoc,
  QueryDocumentSnapshot,
  type DocumentData,
  QueryConstraint,
  limit,
  where,
  startAfter,
  getDocs,
  query,
  updateDoc,
  Timestamp,
  orderBy,
} from 'firebase/firestore';
import { getDownloadURL, getStorage, ref, uploadBytes } from 'firebase/storage';

import type { TestItemI, RequestDataI } from '@/types';
import { useUserStore } from '@/stores/user';

export function createTests() {
  const db = getFirestore();
  const storage = getStorage();
  const docPath = 'tests';
  let lastVisibleTestRef: QueryDocumentSnapshot<DocumentData> | null = null;
  const QUERY_LIMIT = 10;

  return {
    async getAll({
      p,
      queryFieldFilterConstraints,
      queryLimit,
      queryOrderByConstraints,
    }: RequestDataI) {
      const docRef = collection(db, docPath);
      const qLimit = queryLimit ?? QUERY_LIMIT;
      const userStore = useUserStore();

      if (p?.fromStart) {
        lastVisibleTestRef = null;
      }
      if (!queryFieldFilterConstraints) {
        queryFieldFilterConstraints = [];
      }

      const result: TestItemI[] = [];
      let params: QueryConstraint[] = [
        ...queryFieldFilterConstraints,
        where('sourceLang', '==', userStore.user.language || 'en'),
        limit(qLimit),
      ];

      if (queryOrderByConstraints) {
        params = [...params, ...queryOrderByConstraints];
      } else {
        params = [...params, orderBy('order', 'desc')];
      }

      if (!p?.fromStart && lastVisibleTestRef) {
        params = [...params, startAfter(lastVisibleTestRef)];
      }

      const querySnapshot = await getDocs(query(docRef, ...params));

      querySnapshot.forEach((doc) => {
        const test = doc.data() as TestItemI;
        result.push(test);
      });

      const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
      const isLastPage = querySnapshot.size < qLimit;
      console.log('querySnapshot.size', querySnapshot.size);
      lastVisibleTestRef = isLastPage ? null : lastVisible;

      return {
        data: result || [],
        isLastPage,
      };
    },
    async getAllPopular() {
      const params: RequestDataI = { p: { fromStart: true } };
      return await this.getAll({
        ...params,
        queryLimit: 100,
        queryFieldFilterConstraints: [where('isPopular', '==', true)],
        queryOrderByConstraints: [orderBy('popularOrder', 'desc')],
      });
    },
    async getById(id: string) {
      const res = await getDoc(doc(db, docPath, id));
      return res.data() as TestItemI;
    },
    async create(test: TestItemI) {
      const testsRef = collection(db, docPath);
      const testRef = doc(testsRef);
      test.id = testRef.id;
      test.date = Timestamp.now();
      console.log('test', test);
      await setDoc(testRef, test);
    },
    async updateById(id: string, test: Partial<TestItemI>) {
      const docRef = doc(db, docPath, id);
      await updateDoc(docRef, { ...test });
    },

    async uploadThumbnail(thumbnail: Blob, name: string, mini?: boolean) {
      const imgBlob = thumbnail;
      const filename = `thumbnail${mini ? '-mini' : ''}.${
        imgBlob.type.split('/')[1]
      }`;
      const imageRef = ref(storage, `tests/${name}/${filename}`);

      const uploadedFile = await uploadBytes(imageRef, imgBlob);
      console.log('uploadedFile', uploadedFile);
      return await getDownloadURL(uploadedFile.ref);
    },
  };
}
