<template>
  <base-modal
    :is-opened="props.isOpened"
    class="request-notifications"
    @close="handleClose"
  >
    <h2 class="request-notifications__title">
      {{ t('modals.requestNotificationsTitle') }}
    </h2>
    <div class="request-notifications__text">
      {{ t('modals.requestNotificationsText') }}
    </div>
    <div class="request-notifications__text">
      {{ t('modals.requestNotificationsTextSmall') }}
    </div>
    <button
      type="button"
      class="request-notifications__button"
      @click="handleNext"
    >
      {{ t('next') }}
    </button>
  </base-modal>
</template>

<script setup lang="ts">
import { useNotifications } from '@/composables/useNotifications';
import { useRequestNotificationsShowedPrefs } from '@/stores/preferences';

import BaseModal from '@/components/modals/BaseModal.vue';
import { useInternalization } from '@/composables/useInternalization';

const props = defineProps<{
  isOpened: boolean;
}>();
const emit = defineEmits(['close']);

const { notificationService } = useNotifications();
const { t } = useInternalization();

function handleNext() {
  notificationService.registerNotifications(true);
  handleClose();
}

function handleClose() {
  const { set } = useRequestNotificationsShowedPrefs();
  set('yes');
  emit('close');
}
</script>

<style lang="scss" scoped>
.request-notifications {
  &__title {
    font-size: 18px;
    font-weight: 600;
    color: #172659;
    text-align: center;
    margin: 0 0 16px;
  }

  &__text {
    font-size: 15px;
    color: #172659;
    text-align: center;
    margin-bottom: 8px;
  }

  &__button {
    background-color: #6288ff;
    font-size: 16px;
    line-height: 24px;
    color: #fff;
    letter-spacing: 0.32px;
    border-radius: 20px;
    padding: 4px 52px;
    margin-top: 8px;
  }
}

:deep(.modal__container) {
  padding-top: 0;
}
</style>
