import { Capacitor } from '@capacitor/core';
import { NotificationService } from '@/services/notifications';
import { useUserStore } from '@/stores/user';
import { useRouter } from 'vue-router';

let notificationService: NotificationService | undefined;

export function useNotifications() {
  const { isLogged } = useUserStore();
  const router = useRouter();

  function handleNotificationClick(url: string) {
    router.push(url);
  }

  if (!notificationService) {
    notificationService = new NotificationService({
      notificationClickHandler: handleNotificationClick,
      isLogged: isLogged,
      isNative: Capacitor.isNativePlatform(),
    });
  }

  return {
    notificationService,
  };
}
