import {
  doc,
  getFirestore,
  getDoc,
  getDocs,
  query,
  where,
  collection,
} from 'firebase/firestore';
import { StoryI } from '@/types';
import { useUserStore } from '@/stores/user';

export function createStories() {
  const db = getFirestore();
  const docPath = 'stories';

  return {
    async getById(id: string) {
      const res = await getDoc(doc(db, docPath, id));
      return res.data() as StoryI;
    },
    async getAll() {
      const stories: StoryI[] = [];
      const storiesRef = collection(db, docPath);

      const userStore = useUserStore();

      const querySnapshot = await getDocs(
        query(
          storiesRef,
          where('sourceLang', '==', userStore.user.language || 'en')
        )
      );

      querySnapshot.forEach((doc) => {
        const story = doc.data() as StoryI;
        stories.push(story);
      });

      return stories;
    },
    async sortBy(fieldName: string, value: string) {
      const res: StoryI[] = [];
      const storiesRef = collection(db, docPath);
      const themeQuery = query(storiesRef, where(fieldName, '==', value));
      const querySnapshot = await getDocs(themeQuery);
      querySnapshot.forEach((theme) => {
        const story = theme.data() as StoryI;
        res.push(story);
      });

      return res;
    },
  };
}
