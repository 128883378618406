import { useUserStore } from '@/stores/user';
import { getDownloadURL, getStorage, ref } from 'firebase/storage';
import { StatusBar, Style as StatusBarStyle } from '@capacitor/status-bar';
import { NavigationBar } from '@capgo/capacitor-navigation-bar';
import { Capacitor } from '@capacitor/core';
import api from '@/api';
import { AchievementI, SourceLang, UserAchievementI } from '@/types';
import { useInternalization } from '@/composables/useInternalization';

export const getHumanTime = function (date: string | number | Date) {
  const { t } = useInternalization();
  const units = [
    { label: t('time.year'), seconds: 31536000 },
    // { label: 'm', seconds: 2592000 },
    // { label: 'week', seconds: 604800 },
    { label: t('time.day'), seconds: 86400 },
    { label: t('time.hour'), seconds: 3600 },
    { label: t('time.minute'), seconds: 60 },
    { label: t('time.second'), seconds: 1 },
  ];

  const calculateTimeDifference = (time: number) => {
    for (const { label, seconds } of units) {
      const interval = Math.floor(time / seconds);
      if (interval >= 1) {
        return {
          interval: interval,
          unit: label,
        };
      }
    }
    return {
      interval: 0,
      unit: '',
    };
  };

  const time = Math.floor(
    (new Date().valueOf() - new Date(date).valueOf()) / 1000
  );
  const { interval, unit } = calculateTimeDifference(time);

  if (!unit || unit === 's') {
    return 'a few seconds';
  }

  return `${interval}${unit}`;
};

export const capitalizeFirstLetter = function (string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const getFormattedMessage = function (message: string) {
  const emptyStringsCount = 3;
  const emptyStringRegexp = '(<p><br></p>)';
  const regexp = new RegExp(
    `${emptyStringRegexp}{${emptyStringsCount + 1},}`,
    'g'
  );
  const startStringRegexp = new RegExp(`^${emptyStringRegexp}+`, 'g');
  const endStringRegexp = new RegExp(`${emptyStringRegexp}+$`, 'g');
  const emptyStringWithSpacesRegexp = new RegExp('(<p>(\\s)+</p>)', 'g');
  return message
    ?.replaceAll('&nbsp;', ' ')
    .replace(emptyStringWithSpacesRegexp, '<p><br></p>')
    .replace(regexp, '$1'.repeat(emptyStringsCount))
    .replace(startStringRegexp, '')
    .replace(endStringRegexp, '');
};

export const formatTextHyperLinks = function (text: string) {
  return text?.replace(
    /(https?:\/\/|ftps?:\/\/|www\.)((?![.,?!;:()</p>]*(\s|$))\S){2,}/gim,
    '<a href="$&" target="_blank" class="link" onclick="arguments[0].stopPropagation()">$&</a>'
  );
};

export const getFirebaseImagePath = async (src: string) => {
  const storage = getStorage();
  const pathReference = ref(storage, src);
  return await getDownloadURL(pathReference);
};

export const getFirebaseStorageFileUrl = (src: string) => {
  const prodUrl =
    'https://firebasestorage.googleapis.com/v0/b/mental-fdc53.appspot.com/o/';
  const qaUrl =
    'https://firebasestorage.googleapis.com/v0/b/mental-qaa.appspot.com/o/';
  return `${
    process.env.VUE_APP_CONFIG === 'prod' ? prodUrl : qaUrl
  }${encodeURIComponent(src)}?alt=media`;
};

export const getFirebaseStorageResizedAvatar = (
  src: string,
  type: 'icon' | 'profile'
) => {
  if (src.startsWith('blob')) {
    return src;
  }
  const regexp = /\.([\s\S]{3,4})\?alt/;
  const matches = src.match(regexp);
  if (!matches?.[1]) {
    return src;
  }
  return src.replace(
    regexp,
    `_${type === 'icon' ? '64x64' : '240x240'}.${matches[1]}?alt`
  );
};

export const setNativeControlsStyle = async (
  routeNativeControlsStyle: 'dark' | 'light' | null
) => {
  if (Capacitor.isNativePlatform() && Capacitor.getPlatform() === 'android') {
    const darkColor = '#000000';
    const lightColor = '#F8F9FD';

    await StatusBar.setBackgroundColor({
      color: routeNativeControlsStyle === 'dark' ? darkColor : lightColor,
    });
    await StatusBar.setStyle({
      style:
        routeNativeControlsStyle === 'dark'
          ? StatusBarStyle.Dark
          : StatusBarStyle.Light,
    });

    await NavigationBar.setNavigationBarColor({
      color: routeNativeControlsStyle === 'dark' ? darkColor : lightColor,
    });
  }
};

const achievementsIds = {
  peoplePal: 'X6n67Dm9k4Wl8Wkdm3sa',
  motivationMaster: '8070WaTEODyE0kvpDMXU',
  humanityHero: 'Vkf3udWOhKG10MKDjpL2',
  intellectualIcon: 'fDOecfgB6wH8fL5yABHK',
  supportiveSage: 'oYHkGVFmx9I2suxjtlht',
};
type AchievementsTypes =
  | 'peoplePal'
  | 'motivationMaster'
  | 'humanityHero'
  | 'intellectualIcon'
  | 'supportiveSage';
export const updateProgressAchievement = async (
  achievementType: AchievementsTypes,
  type: 'add' | 'remove' | 'reset' = 'add',
  userId?: string
) => {
  const achievementId = achievementsIds[achievementType];
  let user;
  if (userId) {
    user = await api.users.getById(userId);
  } else {
    user = useUserStore().user;
  }

  let userDataAchievement = user.achievements?.find(
    (userAchievement) => userAchievement.id === achievementId
  );
  if (userDataAchievement?.isComplete) {
    return;
  }
  const achievement = await api.achievements.getById(achievementId);
  if (!achievement.levelsGoals) {
    return;
  }
  if (!userDataAchievement) {
    if (type === 'add') {
      userDataAchievement = {
        id: achievementId,
        currentProgress: 1,
        currentLevel: 0,
        isComplete: false,
      };
      if (!user.achievements) {
        user.achievements = [];
      }
      user.achievements.push(userDataAchievement);
    } else if (type === 'remove' || type === 'reset') {
      return;
    }
  } else {
    if (type === 'add') {
      userDataAchievement.currentProgress++;
      if (
        userDataAchievement.currentProgress >=
        achievement.levelsGoals[userDataAchievement.currentLevel]
      ) {
        userDataAchievement.currentLevel++;
        if (!userId) {
          showCompletedAchievement(achievement, userDataAchievement);
        } else {
          userDataAchievement.isNotShowCompleted = true;
        }
      }
      if (userDataAchievement.currentLevel >= achievement.levelsGoals.length) {
        userDataAchievement.isComplete = true;
        if (!userId) {
          showCompletedAchievement(achievement, userDataAchievement);
        } else {
          userDataAchievement.isNotShowCompleted = true;
        }
      }
    } else if (type === 'remove') {
      if (userDataAchievement.currentProgress === 0) {
        return;
      }
      userDataAchievement.currentProgress--;
    } else if (type === 'reset') {
      if (userDataAchievement.currentProgress === 1) {
        return;
      }
      userDataAchievement.currentProgress = 1;
    }
  }

  await api.users.update(user.id, { achievements: user.achievements });
  if (user.achievements) {
    useUserStore().user.achievements = [...user.achievements];
  }
};
export const showCompletedAchievement = (
  completedAchievement: AchievementI,
  userDataCompletedAchievement: UserAchievementI
) => {
  const userStore = useUserStore();
  userStore.completedAchievement = completedAchievement;
  userStore.userDataCompletedAchievement = userDataCompletedAchievement;
  userStore.setIsCompletedAchievement(true);
};
export const clearNotShowCompletedAchievements = () => {
  const userStore = useUserStore();
  if (!userStore.user.achievements) {
    return;
  }
  let isHaveNotShowCompleted = false;
  for (const userAchievement of userStore.user.achievements) {
    if (userAchievement.isNotShowCompleted) {
      isHaveNotShowCompleted = true;
      delete userAchievement.isNotShowCompleted;
    }
  }
  if (isHaveNotShowCompleted) {
    api.users.update(userStore.user.id, {
      achievements: userStore.user.achievements,
    });
  }
};

export function formatStringForUrlQuery(title: string) {
  return title.toLowerCase().replace(/ /g, '_');
}

export function getNormalizedLocale(l: string | SourceLang) {
  let normalizedLang: SourceLang = 'en';
  if (l !== 'en' && l !== 'ru') {
    normalizedLang = navigator.language.split('-')[0] as SourceLang;
    if (normalizedLang !== 'en' && normalizedLang !== 'ru') {
      normalizedLang = 'en';
    }
    return normalizedLang;
  }
  return l as SourceLang;
}
