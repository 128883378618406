<template>
  <base-modal
    :is-opened="props.isOpened"
    is-hide-close
    is-mobile-center
    class="completed-achievement-modal"
    @close="emit('close')"
  >
    <div class="completed-achievement-modal__image">
      <img :src="image" alt="" />
    </div>
    <h2 class="completed-achievement-modal__title">Congratulations!</h2>
    <p class="completed-achievement-modal__text">
      You’ve received an achievement
    </p>
    <div class="completed-achievement-modal__achievement-block">
      <div class="completed-achievement-modal__achievement-title">
        {{ userStore.completedAchievement?.title }}
      </div>
      <div
        v-if="userStore.userDataCompletedAchievement?.currentLevel"
        class="completed-achievement-modal__achievement-level"
      >
        {{ userStore.userDataCompletedAchievement?.currentLevel }} level
      </div>
    </div>
    <button
      type="button"
      class="completed-achievement-modal__see-all-button"
      @click="seeAll"
    >
      See all achievements
    </button>
  </base-modal>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useRouter } from 'vue-router';
import { useUserStore } from '@/stores/user';
import { getFirebaseStorageFileUrl } from '@/services/methods';

import BaseModal from '@/components/modals/BaseModal.vue';

const props = defineProps<{
  isOpened: boolean;
}>();
const emit = defineEmits(['close']);

const router = useRouter();
const userStore = useUserStore();

const image = computed(() => {
  if (!userStore.completedAchievement) {
    return;
  }
  return getFirebaseStorageFileUrl(userStore.completedAchievement.imgPath);
});

function seeAll() {
  router.push({ name: 'MyProfile', query: { tab: 'achievements' } });
  emit('close');
}
</script>

<style lang="scss" scoped>
.completed-achievement-modal {
  --width: 344px !important;
  color: #172659;

  :deep(.modal__container) {
    height: calc(100% - var(--ion-safe-area-bottom));
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: url(/public/assets/completed-achievement-modal-background.webp)
      no-repeat;
    background-size: cover;
    padding: 0;
    margin-top: -20px;
  }

  &__image {
    width: 92px;
    height: 92px;
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: flex-start;
    background: #fff url(/public/assets/icon/achievement-complete-border.svg)
      no-repeat;
    box-shadow: 0 4px 5px 0 rgba(27, 7, 67, 0.02);
    border-radius: 10px;
    margin: 0 auto;

    img {
      width: 24px;
    }
  }

  &__title {
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    margin-top: 16px;
  }

  &__text {
    font-size: 15px;
    line-height: 20px;
    margin-top: 8px;
  }

  &__achievement-block {
    font-weight: 600;
    color: #6289ff;
    border: 1px solid #6288ff;
    border-radius: 20px;
    background-color: #fff;
    text-align: center;
    padding: 16px;
    margin-top: 24px;
  }

  &__achievement-title {
    font-size: 24px;
    line-height: 32px;
  }

  &__achievement-level {
    font-size: 18px;
    line-height: 24px;
  }

  &__see-all-button {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.32px;
    text-decoration: underline;
    background-color: transparent;
    margin-top: 24px;
  }
}
</style>
