import {
  getFirestore,
  getDocs,
  query,
  where,
  collection,
  QueryDocumentSnapshot,
  DocumentData,
  startAfter,
  limit,
  QueryConstraint,
  orderBy,
  doc,
  getDoc,
  QueryFieldFilterConstraint,
  QueryOrderByConstraint,
} from 'firebase/firestore';

import { ApiFetchParamsI, CourseI, TherapyVideoItemI } from '@/types';
import { useUserStore } from '@/stores/user';

interface RequestDataI {
  p?: ApiFetchParamsI;
  queryFieldFilterConstraints?: QueryFieldFilterConstraint[];
  queryLimit?: number;
  queryOrderByConstraint?: QueryOrderByConstraint;
  queryIds?: string[];
}

export function createCourses() {
  const db = getFirestore();
  const docPath = 'courses';
  const quickCalmVideosPath = 'quick-calm-videos';
  const breathingPracticesVideosPath = 'breathing-practices-videos';
  const therapyVideosPath = 'sound-therapy-videos';
  const visualizationVideosPath = 'visualization-therapy-videos';
  const QUERY_LIMIT = 10;
  let lastVisibleCourseRef: QueryDocumentSnapshot<DocumentData> | null = null;

  return {
    async getAll({
      p,
      queryFieldFilterConstraints,
      queryLimit,
      queryOrderByConstraint,
      queryIds,
    }: RequestDataI) {
      const docRef = collection(db, docPath);
      const qLimit = queryLimit || QUERY_LIMIT;
      const userStore = useUserStore();

      if (!queryFieldFilterConstraints) {
        queryFieldFilterConstraints = [];
      }

      const result: CourseI[] = [];
      let params: QueryConstraint[] = [
        ...queryFieldFilterConstraints,
        where('sourceLang', '==', userStore.user.language || 'en'),
        limit(qLimit),
      ];

      if (queryIds) {
        params = [...params, where('id', 'in', queryIds.slice(0, 30))];
      }

      if (queryOrderByConstraint) {
        params = [...params, queryOrderByConstraint];
      } else {
        params = [...params, orderBy('order', 'desc')];
      }

      if (!p?.fromStart && lastVisibleCourseRef) {
        params = [...params, startAfter(lastVisibleCourseRef)];
      }

      const querySnapshot = await getDocs(query(docRef, ...params));

      querySnapshot.forEach(async (doc) => {
        const course = doc.data() as CourseI;
        result.push(course);
      });

      const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
      const isLastPage = querySnapshot.size < qLimit;
      lastVisibleCourseRef = isLastPage ? null : lastVisible;

      return {
        data: result || [],
        isLastPage,
      };
    },
    async searchByQuery(q: string) {
      const rawRes = await fetch(`https://marqo.feelyou.com/courses/?q=${q}`);
      return await rawRes.json();
    },
    async getAllPopular() {
      const params: RequestDataI = { p: { fromStart: true } };
      return await this.getAll({
        ...params,
        queryLimit: 20,
        queryFieldFilterConstraints: [where('isPopular', '==', true)],
        queryOrderByConstraint: orderBy('popularOrder', 'desc'),
      });
    },
    async getAllNew() {
      const params: RequestDataI = { p: { fromStart: true } };
      return await this.getAll({
        ...params,
        queryLimit: 4,
        queryOrderByConstraint: orderBy('date', 'desc'),
      });
    },
    async getAllByThemeId(
      themeId: string,
      subthemeId?: string | null,
      params?: RequestDataI
    ) {
      lastVisibleCourseRef = null;
      const filters = [where('themeId', '==', themeId)];
      if (subthemeId) {
        filters.push(where('subthemeId', 'in', [subthemeId, '']));
      }
      return await this.getAll({
        ...params,
        queryLimit: 10,
        queryFieldFilterConstraints: filters,
      });
    },
    async getById(id: string) {
      const res = await getDoc(doc(db, docPath, id));
      return res.data() as CourseI;
    },
    async getAllQuickCalmVideos() {
      const docRef = collection(db, quickCalmVideosPath);
      const qLimit = 100;

      const userStore = useUserStore();

      const result: TherapyVideoItemI[] = [];
      const params: QueryConstraint[] = [
        where('sourceLang', '==', userStore.user.language || 'en'),
        limit(qLimit),
      ];

      const querySnapshot = await getDocs(query(docRef, ...params));
      querySnapshot.forEach(async (doc) => {
        const course = doc.data() as TherapyVideoItemI;
        result.push(course);
      });

      return result;
    },
    async getAllVisualizationVideos() {
      const docRef = collection(db, visualizationVideosPath);
      const qLimit = 100;

      const userStore = useUserStore();

      const result: TherapyVideoItemI[] = [];
      const params: QueryConstraint[] = [
        where('sourceLang', '==', userStore.user.language || 'en'),
        limit(qLimit),
      ];

      const querySnapshot = await getDocs(query(docRef, ...params));
      querySnapshot.forEach(async (doc) => {
        const course = doc.data() as TherapyVideoItemI;
        result.push(course);
      });

      return result;
    },
    async getVisualizationVideoById(id: string) {
      const res = await getDoc(doc(db, visualizationVideosPath, id));
      return res.data() as TherapyVideoItemI;
    },
    async getQuickCalmVideoById(id: string) {
      const res = await getDoc(doc(db, quickCalmVideosPath, id));
      return res.data() as TherapyVideoItemI;
    },
    async getAllBreathingPracticesVideos() {
      const docRef = collection(db, breathingPracticesVideosPath);
      const qLimit = 100;

      const userStore = useUserStore();

      const result: TherapyVideoItemI[] = [];
      const params: QueryConstraint[] = [
        where('sourceLang', '==', userStore.user.language || 'en'),
        limit(qLimit),
      ];

      const querySnapshot = await getDocs(query(docRef, ...params));
      querySnapshot.forEach(async (doc) => {
        const course = doc.data() as TherapyVideoItemI;
        result.push(course);
      });

      return result;
    },
    async getBreathingPracticeVideoById(id: string) {
      const res = await getDoc(doc(db, breathingPracticesVideosPath, id));
      return res.data() as TherapyVideoItemI;
    },
    async getAllTherapyVideos() {
      const docRef = collection(db, therapyVideosPath);
      const qLimit = 100;

      const result: TherapyVideoItemI[] = [];
      const params: QueryConstraint[] = [limit(qLimit)];

      const querySnapshot = await getDocs(query(docRef, ...params));
      querySnapshot.forEach(async (doc) => {
        const course = doc.data() as TherapyVideoItemI;
        result.push(course);
      });

      return result;
    },
    async getTherapyVideoById(id: string) {
      const res = await getDoc(doc(db, therapyVideosPath, id));
      return res.data() as TherapyVideoItemI;
    },
  };
}
