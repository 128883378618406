import legals from './legals';

const soundTabs = {
  all: 'Все',
  music: 'Музыка',
  asmr: 'АСМР',
  sound: 'Звук',
};

const validations = {
  required: 'Обязательное поле',
  email: 'Неверная почта',
  fieldIsRequired: 'Поле {name} обязательно',
  fieldIsWrong: '{name} не подходит',
  fieldIsNotValid: 'Поле {name} не валидно',
  minLength: 'Минимальная длина {length} симовлов',
  maxLength: 'Максимальная длина {length} симовлов',
  notFound: '{name} не найдено',
  pleaseSelectAnswer: 'Пожалуйста, выберите ответ',
};

const messages = {
  postsNotFound: 'Посты не найдены',
  somethingWentWrong: 'Что-то пошло не так',
  yourReportHasBeenSent:
    'Ваша жалоба была отправлена и будет рассмотрена. Идентификатор жалобы - {reportId}',
  nothingFound: 'Ничего не найдено',
  banOrUnbanUser: '{action} пользователя',
  emergencySupportHTML: `
   	 Пожалуйста, помните: если вы находитесь в опасной для жизни ситуации, не следует полагаться только на сообщество FeelYou. Свяжитесь со 
   	 <b
   		 ><u
   			 ><a
   				 href="https://ya.ru/search/?text=%D1%81%D0%BB%D1%83%D0%B6%D0%B1%D0%B0+%D0%BD%D0%B5%D0%BE%D1%82%D0%BB%D0%BE%D0%B6%D0%BD%D0%BE%D0%B9+%D0%BF%D1%81%D0%B8%D1%85%D0%BE%D0%BB%D0%BE%D0%B3%D0%B8%D1%87%D0%B5%D1%81%D0%BA%D0%BE%D0%B9+%D0%BF%D0%BE%D0%BC%D0%BE%D1%89%D0%B8"
   				 target="_blank"
   				 >службой неотложной психологической помощи</a
   			 ></u
   		 ></b
   	 >
   	чтобы вам немедленно оказали помощь.
    `,
  emergencySupportBannerPriorityHTML: `
Наша платформа не может гарантировать, что вам будет оказана профессиональная помощь в кризисной ситуации. Пожалуйста, свяжитесь со
    <b
   	 ><u
   		 ><a
   			href="https://ya.ru/search/?text=%D1%81%D0%BB%D1%83%D0%B6%D0%B1%D0%B0+%D0%BD%D0%B5%D0%BE%D1%82%D0%BB%D0%BE%D0%B6%D0%BD%D0%BE%D0%B9+%D0%BF%D1%81%D0%B8%D1%85%D0%BE%D0%BB%D0%BE%D0%B3%D0%B8%D1%87%D0%B5%D1%81%D0%BA%D0%BE%D0%B9+%D0%BF%D0%BE%D0%BC%D0%BE%D1%89%D0%B8"
   			 target="_blank"
   			 >службой неотложной психологической помощи</a
   		 ></u
   	 ></b
    >
    или обратитесь к специалисту. Ваше здоровье для нас важнее всего!
    `,
  smartSearchFoundThis: 'Найдено это',
  addStoriesBannerMessageHTML: `
    <p>
   	 Мы убеждены, что ваша история может стать вдохновением для других, кто столкнулся с подобными вызовами. 
    </p>
    <p>
   	 Ваше видео не только поддержит тех, кто нуждается в помощи, но и поможет разрушить стереотипы о психическом здоровье.
    </p>
    <p>
   	Именно ваша история может стать для кого-то спасительной соломинкой. Не бойтесь поделиться своим опытом - ваш голос имеет значение. 
    </p>
    `,
  youHaveNotPosted: `Кажется, вы еще ничего не публиковали`,
  youHaveNotCommented: `Кажется, вы еще не оставили комментариев`,
  commentsNotFound: 'Комментарии не найдены',
  userNotFound: 'Пользователь не найден',
  invalidEmailOrPassword: 'Неправильный адрес электронной почты или пароль',
  emailAlreadyInUse: 'Этот e-mail уже занят',
  yourPostHasBeenCreated: 'Ваш пост был создан',
  coursesNotFound: 'Курсы не найдены',
  authSessionExpired:
    'Сессия аутентификации истекла, пожалуйста, войдите снова',
};

const modals = {
  deletePhotoTitle: 'Вы уверены, что хотите удалить фотографию?',
  logoutTitle: 'Вы уверены, что хотите выйти из аккаунта?',
  deleteAccountTitle: 'Удалить аккаунт?',
  deleteAccountText:
    'Удаление вашей учетной записи приведет к потере всей вашей информации. Вы не сможете отменить это действие.',
  deleteCourseTitle: 'Вы уверены, что хотите удалить этот курс?',
  deleteCourseText:
    'Удаление приведет к полной потере вашего прогресса и достигнутых результатов.',
  expertAdvicePaymentTitle:
    'Хотите получить рекомендацию от лицензированного психолога?',
  expertAdvicePaymentHTMLText: `
  	<li>Специалист предоставит вам профессиональный и детальный ответ.</li>
  	<li>Наша команда состоит из опытных психологов с многолетним практическим опытом.</li>
  	<li>Ответ специалиста будет адаптирован к вашей конкретной ситуации на основе вашего поста.</li>
  	<li>Вы можете оставаться анонимным и не раскрывать личную информацию.</li>
   	 `,
  chooseLanguageTitle: 'Выберите язык',
  chooseLanguageText: 'Вы можете изменить предпочитаемый язык в настройках',
  emergencyHelpTitle: 'Внимание!',
  emergencyHelpText: `Если вы оказались в ситуации опасной для жизни и нуждаетесь в срочной помощи, обратитесь за помощью в службу неотложной психологической помощи`,
  emergencyHelpSmallText: 'Найдите ближайшую службу поддержки в Google',
  emergencyHelpSupportBtn: 'Неотложная помощь',
  emergencyHelpRejectBtn: `Не нужна срочная помощь`,
  rateCourseTitle: 'Вы прошли курс',
  rateCourseText: 'Пожалуйста, поделитесь своим мнением о курсе.',
  rateCourseThankForFeedback: 'Спасибо за ваш отзыв!',
  rateCourseShareYourReview: 'Пожалуйста, поделитесь своим отзывом на',
  rateCourseAfterFinishText: 'Как вы себя чувствуете после окончания курса?',
  notStartedCourseText: 'Для просмотра видео необходимо начать курс.',
  tutorialCourseTitle: '4 совета перед стартом',
  tutorialCourseTextItems: [
    'Начните с теории, затем переходите к практике. Вы сможете пересмотреть все видео после завершения курса.',
    'Приготовьте ручку и блокнот.',
    'Запланируйте определённое время, чтобы избежать прокрастинации.',
    `Помните, что остановка на полпути не даст желаемых результатов.`,
  ],
  requestNotificationsTitle: 'Оставайтесь на связи с нашим сообществом!',
  requestNotificationsText:
    'Мы уведомим вас, когда психологи и другие пользователи ответят на ваши сообщения.',
  requestNotificationsTextSmall:
    'Нажмите “далее”, чтобы разрешить уведомления.',
  watchedVideoTitle: 'Вы посмотрели видео',
  watchedVideoText: 'Как вы себя сейчас чувствуете?',
  appFeedbackQuestionTitle: 'Вам нравится FeelYou?',
  appFeedbackQuestionText:
    'Ваша оценка поможет привлечению других пользователей, которые нуждаются в нашей помощи и поддержке.',
  appFeedbackResponseTitle: 'Спасибо за обратную связь',
  appFeedbackNegativeResponseText:
    'Мы приложим все усилия, чтобы сделать FeelYou лучше. Напишите в службу поддержки и помогите развитию нашего сообщества.',
  appFeedbackPositiveResponseText:
    'Мы рады слышать, что вам нравится FeelYou. Пожалуйста, потратьте немного времени, чтобы оценить приложение и поделиться отзывом.',
  unavailableVideo: `Мы организовали видео в этом курсе в определенном порядке, чтобы вы могли получить максимально возможную помощь. Это видео будет доступно только после просмотра предыдущих.`,
  donateTitle: 'Ваши пожертвования важны!',
  donateDescription: `Присоединяйтесь к борьбе за доступную помощь и помогайте спасать жизни.`,
  donateSupportBtn: 'Поддержать проект',
  donateSupportHint: 'Фандрайзинг происходит через Donorbox ',
  donateListTitle: 'С вашей поддержкой мы продолжим:',
  donateListItems: [
    'Привлекать лицензированных психологов.',
    'Предоставлять доступные терапевтические инструменты.',
    'Вовлекать волонтеров.',
    'Делиться полезным контентом.',
  ],
  postPurchaseInfoTitle: 'Опубликуйте пост сейчас, чтобы получить: ',
  postPurchaseInfoItems: [
    '<b>Быструю модерацию:</b> оперативный доступ к поддержке сообщества',
    '<b>Совет от специалиста:</b> профессиональный и подробный ответ на ваш пост',
    '<b>Приоритетную поддержку</b> волонтеров',
  ],
  postPurchaseInfoBuyBtn: 'Опубликовать за 299₽',
  postPurchaseInfoCancelBtn: 'Позже',
  postPurchaseDelayInfoTitle: 'Отложенный постинг:',
  postPurchaseDelayInfoItems: [
    'Модерация поста: <b>18 часов</b>',
    '<b>Без ответа</b> специалиста',
    '<b>Отложенная поддержка</b> сообщества ',
    '<b>Минимальная помощь</b> волонтёров',
  ],
  postPurchasePostponeBtn: 'Отложить мой пост',
  deletePostTitle: 'Удалить пост',
  deletePostText: 'Вы уверены, что хотите удалить этот пост?',
  deletePostConfirmBtn: 'Да',
  deletePostCancelBtn: 'Нет',
};

const time = {
  year: 'г',
  day: 'д',
  hour: 'ч',
  minute: 'м',
  second: 'с',
};
const weekdays = {
  monday: 'Пн',
  tuesday: 'Вт',
  wednesday: 'Ср',
  thursday: 'Чт',
  friday: 'Пт',
  saturday: 'Сб',
  sunday: 'Вс',
};
const moodItems = {
  terrible: 'ужасно',
  bad: 'плохо',
  okay: 'нормально',
  good: 'хорошо',
  excellent: 'отлично',
};
const emotionItems = {
  tired: 'усталость',
  content: 'удовлетворение',
  confused: 'смущение',
  grateful: 'радость',
  motivated: 'мотивация',
  anxious: 'тревожность',
  relaxed: 'расслабление',
  stressed: 'стресс',
  sad: 'грусть',
  proud: 'гордость',
  unmotivated: 'демотивация',
  annoyed: 'раздражение',
  loving: 'любовь',
  happy: 'счастье',
  worried: 'беспокойство',
  excited: 'восторг',
  angry: 'злость',
  hopeless: 'безнадёжность',
  panicking: 'паника',
  exhausted: 'истощение',
};
const sleepItems = {
  poor: 'плохо',
  okay: 'нормально',
  good: 'хорошо',
  awesome: 'потрясающе',
};
const symptomItems = {
  panicAttack: 'Паническая атака',
  constantSadness: 'Постоянная печаль',
  moodSwings: 'Перепады настроения',
  selfHarmingThoughts: 'Мысли о селфхарме',
  isolation: 'Желание спрятаться',
  thinkingCircles: 'Навязчивые мысли',
  excessiveEmotions: 'Чрезмерные эмоции',
  lowAppetite: 'Сильный/слабый аппетит',
  procrastination: 'Прокрастинация',
  anxiousThoughts: 'Тревожные мысли',
  fear: 'Страх',
  healthConcerns: 'Проблемы со здоровьем',
};

const onboardingItems = [
  {
    heading: 'Добро пожаловать в сообщество FeelYou!',
    text: 'Сейчас мы расскажем, как поможем улучшить ваше ментальное здоровье.',
    buttons: {
      primary: {
        text: 'Отлично, давайте начнём!',
      },
      secondary: {
        text: 'Нет, спасибо',
      },
    },
  },
  {
    text: 'Анонимно расскажите свою историю, чтобы получить поддержку сообщества и совет специалиста.',
    buttons: {
      primary: {
        text: 'Поделиться историей',
      },
      secondary: {
        text: 'Далее',
      },
    },
  },
  {
    text: 'Попробуйте техники терапии: видеокурсы, тесты, дыхательные упражнения и аудиопрактики.',
    buttons: {
      primary: {
        text: 'Перейти к терапии',
      },
      secondary: {
        text: 'Далее',
      },
    },
  },
  {
    text: 'Получите моментальную пошаговую поддержку в тот момент, когда эмоции переполняют.',
    buttons: {
      primary: {
        text: 'Получить помощь',
      },
      secondary: {
        text: 'Далее',
      },
    },
  },
  {
    heading: 'Вы на верном пути к улучшению вашего психического здоровья.',
    text: 'Помните, вы не одиноки!',
    buttons: {
      primary: {
        text: 'Начать',
      },
    },
  },
];

const authFeatureList = [
  'Изучите персонализированную ленту',
  'Публикуйте сообщения и присоединяйтесь к обсуждениям',
  `Получите комментарии с советами психолога`,
  'Доступ к видеороликам по самотерапии',
  'Ведите дневник терапии и настроения',
];

const tutorialItems = {
  feed: {
    title: 'Это <b>Лента</b>',
    text: 'здесь пользователи делятся своими историями, оставляют комментарии и получают советы от наших психологов',
  },
  discover: {
    title: 'В разделе <b>Темы</b>',
    text: 'вы можете изучать любую интересующую вас тему и получать доступ ко всей информации и инструментам, связанными с ней',
  },
  therapy: {
    title: ' Раздел <b>Терапия</b>',
    text: 'включает в себя курсы с практическими упражнениями, аудиопрактики и психологические тесты.',
  },
  help: {
    title: 'Нажав кнопку <b>Помощь</b>',
    text: 'вы получите моментальную поддержку, когда чувства переполняют, а проблемы кажутся неразрешимыми.',
  },
  stories: {
    title: 'А это <b>Истории</b>',
    text: 'где наши пользователи делятся своими видео-историями, рассказывают о своих проблемах и как они их преодолевали.',
  },
  myProfile: {
    title: 'В <b>Профиле</b>',
    text: 'вы легко можете отслеживать свои посты, комментарии, избранное и достижения.',
  },
};

const notificationItems = {
  commentOnPost: 'Вас ждет ответ от нового пользователя',
  replyOnComment: 'Новый ответ на ваш комментарий',
  specialistCommentOnPost: 'Специалист прокомментировал ваш пост',
  newCommentOnParticipatedPost:
    'Обсуждение поста, где вы оставили комментарий, возобновилось',
  favedPost: 'Появились новые комментарии к посту в избранном',
  postponedCheckToPostAuthor: `Привет! Вы разместили пост на FeelYou 30 дней назад. Загляните в свою публикацию и расскажите, как вы себя сегодня чувствуете.`,
};

export default {
  validations,
  messages,
  modals,
  time,
  weekdays,
  moodItems,
  emotionItems,
  sleepItems,
  symptomItems,
  onboardingItems,
  authFeatureList,
  tutorialItems,
  notificationItems,
  soundTabs,
  legals: legals.ru,
  send: 'Отправить',
  feed: 'Лента',
  therapy: 'Терапия',
  help: 'SOS',
  stories: 'Истории',
  profile: 'Профиль',
  onboarding: 'Онбординг',
  auth: 'Авторизация',
  signIn: 'Вход',
  signUp: 'Регистрация',
  category: 'Категория',
  categories: 'Категории',
  coursesOnThisTopic: 'Курсы на эту тему',
  discussionOnThisTopic: 'Обсуждение в этой теме',
  discover: 'Темы',
  pickTopicsThatInterestYou: '20+ рубрик',
  search: 'Поиск',
  findSimilarStories: 'Похожие истории',
  speakOut: 'Выскажитесь',
  tellYourStoryAndGetHelp: 'Помощь и поддержка',
  emergencySupport: 'Неотложная помощь',
  personalTasks: 'Личные задания',
  dailyCheck: 'Дневник настроения',
  seeOtherCourses: 'Другие курсы',
  finishedCourses: 'Законченные курсы',
  quickCalmingTools: 'Быстрое успокоение',
  quickCalmingDescription:
    'Эффективные техники расслабления в момент сильных эмоциональных переживаний',
  tests: 'Тесты',
  testsDescription:
    'Скрининг-тесты, чтобы лучше понять ваше настроение, мышление и поведение',
  seeOtherTests: 'Другие тесты',
  breathingPractices: 'Дыхательные практики',
  breathingPracticesDescription:
    'Простые упражнения, которые помогут вам почувствовать себя спокойнее и расслабленнее',
  soundTherapy: 'Аудиопрактики',
  soundTherapyDescription:
    'Умиротворяющие звуки, музыка и АСМР для обретения спокойствия',
  today: 'Сегодня',
  shareYourStory: 'Поделиться своей историей!',
  storiesOfCelebrites: 'Истории знаменитостей',
  comingSoon: 'Скоро',
  celebritesStoryDescription: `Ты не одинок. Знаменитости тоже борются с проблемами ментального здоровья. Посмотри их истории и найди вдохновение в их силе.`,
  storiesFromOurUsers: 'Истории наших пользователей',
  storiesFromOurUsersDescription:
    'Узнай, как наши пользователи преодолевали трудности на пути к выздоровлению.',
  all: 'Все',
  specialist: 'Специалист',
  verifiedByFeelyou: 'Проверено Feelyou',
  noDescription: `Кажется, здесь еще нет описания`,
  noFavorites: `Кажется, вы еще не добавили ни одного избранного`,
  posts: 'Посты',
  comments: 'Комментарии',
  achievements: 'Достижения',
  favorites: 'Избранное',
  aboutMe: 'Обо мне',
  editProfile: 'Редактировать профиль',
  yes: 'Да',
  no: 'Нет',
  confirm: 'Подтвердить',
  cancel: 'Отменить',
  deleteAccount: 'Удалить аккаунт',
  logout: 'Выйти',
  changeEmail: 'Изменить почту',
  changePassword: 'Изменить пароль',
  changeName: 'Изменить имя',
  editPhoto: 'Изменить фотографию',
  save: 'Сохранить',
  name: 'Имя',
  oldPassword: 'Старый пароль',
  newPassword: 'Новый пароль',
  password: 'Пароль',
  email: 'Почта',
  newEmail: 'Новая почта',
  share: 'Поделиться',
  getExpertAdvice: 'Получить ответ специалиста',
  specialistAnswer: 'Ответ специалиста',
  edited: 'Изменено',
  seeMore: 'Показать больше',
  ago: 'назад',
  userBanned: 'Пользователь заблокирован',
  explore: 'Курсы',
  popularCourses: 'Популярные курсы',
  newCourses: 'Новые курсы',
  searchForCoureses: 'Поиск курсов',
  mostPopular: 'Самые популярные',
  allTests: 'Все тесты',
  yourNotes: 'Ваши заметки',
  mood: 'Настроение',
  emotions: 'Эмоции',
  sleep: 'Сон',
  symptoms: 'Симптомы',
  apply: 'Записать',
  update: 'Обновить',
  theoryLessons: 'Нет теоретических уроков | Теория - {n} | Теория - {n}',
  practicalExercises:
    'Нет практических упражнений | Практика - {n} | Практика - {n}',
  perDay: 'в день',
  firstAidKitVideos:
    'Нет видео первой помощи | «Первая помощь» - {n} | «Первая помощь» - {n}',
  firstAidKitVideo: '«Первая помощь»',
  exercise: 'Упражнение',
  video: 'Видео',
  turnOnVideoSound: 'Включить звук видео',
  continueWithoutSound: 'Продолжить видео без звука',
  selectAToppic: 'Выберите тему',
  createPost: 'Создать пост',
  createPostHelpText: `Чтобы получить максимальную помощь и поддержку от нашего сообщества
нам бы хотелось услышать вашу историю настолько подробно, насколько вам будет комфортно ею поделиться.`,
  youAreBlocked: 'Вы заблокированы',
  okay: 'Окей',
  title: 'Заголовок',
  message: 'Сообщение',
  copyLink: 'Скопировать ссылку',
  copied: 'Скопировано',
  shareToApps: 'Поделиться в приложении',
  delete: 'Удалить',
  edit: 'Редактировать',
  hide: 'Скрыть',
  show: 'Показать',
  unblock: 'Разблокировать',
  report: 'Пожаловаться',
  comment: 'Комментарий',
  ban: 'Заблокировать',
  unban: 'Разблокировать',
  user: 'Пользователь',
  yourNote: 'Ваша заметка',
  success: 'Успешно!',
  yourPostIsCreated: 'Ваш пост создан',
  thereAreCoursesInThisProblem: 'Посмотрите наши курсы по этой теме',
  seePostsWithSimilarProblems:
    'Посмотрите посты, в которых обсуждаются похожие проблемы',
  postCreated: 'Пост создан',
  shareYourThoughtsInAPost:
    'Пожалуйста, поделитесь своими переживаниями и проблемами в посте',
  helpSpeakOutListHTML: `
   	 <li>
   		 Лицензированные специалисты предоставят вам профессиональный и
   		 подробный ответ.
   	 </li>
   	 <li>
   		 Наше сообщество всегда здесь, чтобы помочь и поддержать вас.
   	 </li>
    `,
  helpSearchText: `Найдите утешение и надежду в историях наших пользователей, которые уже
справились с подобной проблемой.`,
  notifications: 'Уведомления',
  intelligentSearch: 'Интеллектуальный поиск',
  aiSearchTitle: 'Пытаетесь найти правильный ответ?',
  aiSearchMessage:
    'Превратите свой поисковый запрос в пост и получите поддержку, которую вы заслуживаете',
  convert: 'Преобразовать',
  examples: 'Примеры',
  recommendedTopics: 'Рекомендуемые темы',
  aiSearchDisclaimer: `Если вы пока не готовы поделиться своими опасениями, напишите несколько
фразы о ваших переживаниях и наш умный поиск найдет похожие
истории, которые соответствуют вашим проблемам.`,
  aiSearchPlaceholderItems: [
    'Новый эпизод панической атаки, нужна помощь',
    'Я часто пробую самые случайные вещи, чтобы успокоиться.',
    'Недавно мне поставили диагноз шизофрения.',
  ],
  changeLanguage: 'Изменить язык',
  about: 'О нас',
  becomeAVolunteer: 'Стать волонтером',
  support: 'Поддержка',
  userFAQ: 'Часто задаваемые вопросы',
  legal: 'Юридическая информация',
  typeComment: 'Написать комментарий',
  post: 'Пост',
  videoStories: 'Видеоистории от наших пользователей',
  addStoriesFooterText:
    'Отправьте нам электронное письмо, и мы обсудим все детали и предоставим любую помощь, которая может вам понадобиться.',
  emailUs: 'Свяжитесь с нами по e-mail',
  therapistAdvice: 'Консультация психолога',
  expertAdvicePaymentTitle: 'Как получить персональную консультацию?',
  expertAdvicePaymentInfo:
    'После оплаты, вы получите приоритетный ответ от нашего специалиста.',
  payNow: 'Оплатить сейчас',
  weSupportBannerPeople: 'человек | человек | людей',
  receivedOurSupport: 'получили нашу поддержку {when}',
  helpUsGrow: 'Помогите нам стать лучше',
  rateOurApp: 'оцените наше приложение',
  andShareYourThoughts: '',
  yesterday: 'Вчера',
  weSupportedDonateTitle: `Помогите нам спасти чью-то жизнь`,
  weSupportedDonateDescription: `Поддержите <b>FeelYou</b> своими пожертвованиями и помогите нам предложить еще больше функций для тех, кто нуждается в поддержке.`,
  reply: 'Ответить',
  level: 'Уровень',
  test: 'Тест',
  questionsCount: '0 вопросов | 1 вопрос | Вопросов - {n}',
  testBenefitsTitle: 'Что вы узнаете:',
  relatedCourses: 'Связанные курсы',
  disclaimer: 'Дисклеймер',
  testDisclaimer: `
		Цель этого теста - помочь вам узнать больше о себе и лучше понять свои чувства.
		<br>
		<br>
		Результаты теста носят ознакомительный характер и не позволяют поставить медицинский диагноз. Если вам требуется профессиональная помощь, пожалуйста, свяжитесь со службой неотложной психологической помощи или обратитесь к специалисту.
`,
  getStarted: 'Начать',
  skip: 'Пропустить',
  next: 'Далее',
  final: 'Финал',
  finish: 'Завершить',
  restartTest: 'Пройти тест еще раз',
  or: 'или',
  beginNow: 'Начать сейчас',
  course: 'Курс',
  letsStart: 'Начать',
  continue: 'Продолжить',
  relatedToolsTitle: 'Рекомендуем попробовать',
  relatedToolsDescription:
    'Дополнительные инструменты, которые могут помочь вам более эффективно проработать вашу проблему.',
  theoryLessonsTitle: 'Теоретические уроки',
  practicalExercisesTitle: 'Практические упражнения',
  courseDisclaimerItems: [
    `Цель этого курса - помочь вам разобраться в данной теме и лучше понять свои чувства. Курс не заменяет медицинское лечение и не может использоваться для постановки медицинских диагнозов. Если вам нужна профессиональная помощь, пожалуйста, незамедлительно обратитесь в службу неотложной помощи или свяжитесь со специалистом .`,
  ],
  signupNow: 'Зарегистрируйтесь сейчас',
  authButtonsText: 'Уже есть аккаунт?',
  generate: 'Сгенерировать',
  termsOfUse: 'Пользовательское соглашение',
  privacyPolicy: 'Политика конфиденциальности',
  DMCA: 'DMCA',
  agreeToOurPolicy: `Нажимая «Зарегистрироваться», вы соглашаетесь с нашими`,
  forgotPassword: 'Забыли пароль?',
  hello: 'Привет',
  nextVideo: 'Следующее видео',
  coursePage: 'Страница курса',
  allCourses: 'Все курсы',
  reviewLater: 'Оценить позже',
  contactUs: 'Написать нам',
  leaveReview: 'Оставить отзыв',
  yourResult: 'Ваш результат',
  finished: 'Законченный',
  inProgress: 'В процессе',
  popularCoursesBarBannerHTML: `Наши <span>бесплатные видео-курсы</span> по теме {name}`,
  seeResults: 'Посмотреть результат',
  yourTestResult: 'Ваш балл: {x} из {y}',
  postNow: 'Опубликовать сейчас',
  waitingForPublishBanner:
    'Ваш пост в очереди и будет опубликован через {time} часов.',
  postPaymentRequested:
    'Процесс оплаты. Ваш пост будет опубликован после подтверждения оплаты.',
  testResultsTopicBannerTitle: 'Хотите глубже проработать этот вопрос?',
  testResultsTopicBannerText:
    'Изучите полезные ресурсы от наших специалистов, которые помогут вам разобраться в проблеме.',
  testResultsRelatedCourses: 'Начните с этих курсов',
  author: 'Автор',
  showMoreReplies: 'Показать больше ответов',
  therapySections: {
    courses: {
      title: 'Видео курсы',
      description: 'Программы от специалистов',
    },
    tests: {
      title: 'Тесты',
      description: 'Психологические скрининг-тесты',
    },
    quickCalming: {
      title: 'Быстрое успокоение',
      description: 'КПТ техники от негативных мыслей',
    },
    breathingPractices: {
      title: 'Дыхательные практики',
      description: 'Упражнения для расслабления',
    },
    soundTherapy: {
      title: 'Аудиопрактики',
      description: 'Умиротворяющие аудио',
    },
    visualization: {
      title: 'Визуализация',
      description: 'Видео-медитация, чтобы переключиться',
    },
  },
  quickCalmingListTabs: {
    all: 'Все',
    conflict: 'Конфликт',
    panic: 'Паника',
    stress: 'Стресс',
    negativeThought: 'Негативные мысли',
    anger: 'Злость',
    pain: 'Боль',
    anxiety: 'Тревога',
    fallAsleep: 'Засыпание',
    fear: 'Страх',
    panickAtacks: 'Паническая атака ',
    tension: 'Напряжение',
  },
};
