import { collection, doc, getFirestore, setDoc } from 'firebase/firestore';
import { ExpertRequestI } from '@/types';

export function createExpertRequests() {
    const db = getFirestore();
    const expertRequestsPath = 'expert-requests';

    return {
        async create(data: ExpertRequestI) {
            const expertRequestCollection = collection(db, expertRequestsPath);
            const expertRequestRef = doc(expertRequestCollection);
            await setDoc(expertRequestRef, data);
        }
    }
}