import { collection, doc, getDoc, getDocs, getFirestore, limit, orderBy, query } from 'firebase/firestore';
import { AchievementI } from '@/types';

export function createAchievements() {
  const db = getFirestore();
  const achievementsPath = 'achievements';

  return {
    async getAll(queryLimit = 20) {
      const achievements: AchievementI[] = [];
      const achievementsCollection = collection(db, achievementsPath);
      const achievementsQuery =
        query(achievementsCollection, orderBy('order', 'asc'), limit(queryLimit));
      const querySnapshot = await getDocs(achievementsQuery);
      querySnapshot.forEach(doc => {
        const achievement = doc.data() as AchievementI;
        achievements.push(achievement);
      });
      return achievements;
    },
    async getById(id: string) {
      const res = await getDoc(doc(db, achievementsPath, id));
      return res.data() as AchievementI;
    }
  }
}
